import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  getAmericanDate,
  getAmericanOSDate,
  getDate,
  getOSDate,
} from '../../../../../core__events-utils';
import { ED, OSDW, Live } from 'UI/apps/EventsApp/EventDate';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { Translations } from '../../../../../core__events-app';
import { Constants, event_status } from '../../../../../core__events-constants';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { useInterval } from 'Services/hooks/core__interval';

/**
 * @param {boolean} timerActive
 * @param {number} inPlayTime
 * @param {object} event
 */
export const EventDate = ({
  event,
  showDateBelow,
  pageLayoutIsAmerican,
  category,
}) => {
  const [tennisData, setTennisData] = useState({});
  const [tableTennisData, setTableTennisData] = useState({});
  const [volleyballData, setVolleyballData] = useState({});
  const [dartsData, setDartsData] = useState({});
  const [soccerData, setSoccerData] = useState({});
  const [timerActive, setTimerActive] = useState(false);
  const [inPlayTime, setInPlayTime] = useState(null);
  const [eventSocketListener, setEventSocketListener] = useState(null);
  const [gameData, setGameData] = useState({});

  useEffect(() => {
    subscribeEventSocket();
    listenEventSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      eventSocketListener && eventSocketListener.unsubscribe();
    };
  }, [eventSocketListener]);

  const subscribeEventSocket = () => {
    const subscriptionObject = {
      categoryRef: category.ref,
      providerRef: event.providerRef,
      sourceKey: event.sourceKey,
    };
    PubSub.emit(PubsubEvents.SOCKET_EVENT_SUBSCRIBER, subscriptionObject);
  };

  const listenEventSocket = () => {
    const listener = PubSub.listen(PubsubEvents.SOCKET_EVENT_LISTEN, wsData => {
      if (wsData.sourceKey === event.sourceKey) {
        //set data for tennis
        if (wsData.catRef === Constants.TENNIS_REF) {
          setTennisData(wsData.tennisData);
        }
        if (wsData.catRef === Constants.VOLLEYBALL_REF) {
          setVolleyballData(wsData.volleyballData);
        }
        if (wsData.catRef === Constants.TABLE_TENNIS_REF) {
          setTableTennisData(wsData.scoreSummary.tableTennisData);
        }
        if (wsData.catRef === Constants.DARTS_REF) {
          setDartsData(wsData.dartsData);
        }
        if (wsData.catRef === Constants.SOCCER_REF) {
          setSoccerData(wsData);
        }
        // set game timer
        if (event.state === event_status.LIVE) {
          //for some data there's minutes instead of minute
          if (wsData?.minutes) {
            wsData.minute = wsData?.minutes;
          }
          if (wsData?.seconds) {
            wsData.second = wsData?.seconds;
          }
          if (wsData?.minute !== undefined && wsData?.second !== undefined) {
            setTimerActive(true);

            if (
              wsData.catRef === Constants.SOCCER_REF &&
              wsData.extratime &&
              wsData?.minute > 90
            ) {
              setInPlayTime(
                parseInt(wsData?.minute - 90) * 60 + parseInt(wsData.second)
              );
            } else {
              setInPlayTime(
                parseInt(wsData.minute) * 60 + parseInt(wsData.second)
              );
            }
          }
        }

        //set game data for period and state
        const updatedGameData = {};
        if (wsData.period) {
          updatedGameData['period'] = wsData.period;
        }
        if (wsData.state) {
          updatedGameData['state'] = wsData.state;
        }
        setGameData(updatedGameData);
      }
    });

    //add it to state for cleanup later
    setEventSocketListener(listener);
  };

  useInterval(() => {
    if (
      inPlayTime !== null &&
      gameData.period !== GLOBAL_CONSTANTS.BREAK_TIME &&
      gameData.state !== GLOBAL_CONSTANTS.COMPLETED
    ) {
      if (category.ref === Constants.BASKETBALL_REF) {
        inPlayTime > 0 && setInPlayTime(inPlayTime - 1);
      } else {
        setInPlayTime(inPlayTime + 1);
      }
    }
  }, 1000);
  if (
    category.ref === Constants.TENNIS_REF &&
    event.state === GLOBAL_CONSTANTS.INPLAY
  ) {
    return (
      <ED isTennis={category.ref === Constants.TENNIS_REF}>
        <span>{tennisData.set}&nbsp;</span>
        <span>{tennisData.currentSetScore}&nbsp;</span>
        <span>{tennisData.gameScore}</span>
      </ED>
    );
  } else if (
    category.ref === Constants.VOLLEYBALL_REF &&
    event.state === GLOBAL_CONSTANTS.INPLAY
  ) {
    return (
      <ED isVolleyball={category.ref === Constants.VOLLEYBALL_REF}>
        <span>{volleyballData?.set}</span>
        <span>{volleyballData?.score}</span>
        <span>{volleyballData?.currentSetScore}</span>
      </ED>
    );
  } else if (
    category.ref === Constants.DARTS_REF &&
    event.state === GLOBAL_CONSTANTS.INPLAY
  ) {
    return (
      <ED isDarts={category.ref === Constants.DARTS_REF}>
        <span>
          {dartsData?.setNumber &&
            `${Translations.get('text.best.of')} ${dartsData.setNumber}`}
        </span>
        <span>{dartsData?.gameScore}</span>
      </ED>
    );
  } else if (
    category.ref === Constants.TABLE_TENNIS_REF &&
    event.state === GLOBAL_CONSTANTS.INPLAY
  ) {
    return (
      <ED isTableTennis={category.ref === Constants.TABLE_TENNIS_REF}>
        <span>{tableTennisData.set}</span>
        <span>{tableTennisData.currentSetScore}</span>
      </ED>
    );
  } else {
    if (!timerActive && event.state === GLOBAL_CONSTANTS.INPLAY) {
      return showDateBelow ? (
        <Live>
          <ED>{Translations.get('text.live-caps')}</ED>
        </Live>
      ) : (
        <ED>{Translations.get('text.live')}</ED>
      );
    }
    if (
      showDateBelow &&
      !timerActive &&
      event.state === GLOBAL_CONSTANTS.INPLAY
    ) {
      return (
        <Live>
          <ED>{Translations.get('text.live')}</ED>
        </Live>
      );
    }
    // genrate Event Date

    const date = timerActive
      ? ('0' + Math.floor(inPlayTime / 60)).slice(-2) +
        ':' +
        ('0' + Math.floor(inPlayTime % 60)).slice(-2)
      : showDateBelow
      ? pageLayoutIsAmerican
        ? getAmericanOSDate(event)
        : getOSDate(event)
      : pageLayoutIsAmerican
      ? getAmericanDate(event)
      : getDate(event);
    if (showDateBelow) {
      return (
        <OSDW>
          <p>{date.split(',')[0]}</p>
          <p>{date.split(',')[1]}</p>
        </OSDW>
      );
    } else if (soccerData?.isPenalty) {
      return <ED> {`${Translations.get('text.penalties')} ${date}`}</ED>;
    } else if (soccerData?.extratime) {
      return <ED> {`${Translations.get('text.et')} ${date}`}</ED>;
    } else {
      return <ED>{date}</ED>;
    }
  }
};
EventDate.propTypes = {
  timerActive: PropTypes.bool,
  inPlayTime: PropTypes.number,
  event: PropTypes.object,
  showDateBelow: PropTypes.bool,
  pageLayoutIsAmerican: PropTypes.bool,
  category: PropTypes.object,
  tennisData: PropTypes.object,
};
