import styled from 'styled-components';
import { FONT, GREYS } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';

import { ED, OSDW, Live } from 'CORE__UI/apps/EventsApp/core__eventDate';

const ED_GDWN = styled(ED)`
  color: ${FONT.lightMute};
  background-color: ${GREYS.silverD};
  line-height: 20px;
  margin: 2px 0;
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: ${BREAKPOINT_S}) {
    line-height: 16px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 11px;
  }
`;
export { ED_GDWN as ED, OSDW, Live };
