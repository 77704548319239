import * as markets from './core__events-markets';

const translationString = [
  'text.oops',
  'text.live',
  'text.live-caps',
  'text.sorry.we.cant.find.the.page.you.are.looking.for',
  'text.here.are.a.few.links.that.may.be.helpful',
  'text.no.events',
  'text.hl',
  'market.o',
  'market.u',
  'number.nd',
  'number.st',
  'number.th',
  'number.rd',
  'month.jan',
  'month.feb',
  'month.mar',
  'month.apr',
  'month.may',
  'month.jun',
  'month.jul',
  'month.aug',
  'month.sep',
  'month.oct',
  'month.nov',
  'month.dec',
  'markets.set',
  'markets.viewless',
  'markets.viewmore',
  'ou',
  'opar',
  'message.market.1x2',
  'message.market.tpm',
  'text.spread',
  'text.total',
  'text.moneyline',
  'text.home',
  'text.away',
  'text.draw',
  'text.over',
  'text.runline',
  'text.under',
  'text.livenow',
  'text.2ball',
  'text.3ball',
  'text.yes',
  'text.no',
  'text.either',
  'text.livenow',
  'text.best.of',
  'text.et',
  'text.penalties',
  'message.market.mmafgd',
  'text.na',
  'text.dc.home',
  'text.dc.away',
  'text.dc.either',
  'time.am',
  'time.pm',
  'text.scheduledevents',
];

let marketsToTranslate = [];

Object.values(markets).forEach(value => {
  marketsToTranslate = marketsToTranslate.concat(value.markets);
});

export { translationString, marketsToTranslate };
