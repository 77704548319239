import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';

import { connect } from 'react-redux';
import createStore, {
  fetchData,
  commonFunction,
  getSubcatRef,
} from './core__events-store';
import EventsAppReact from './component/core__eventsReact';
import ScDecoder from 'Services/json/core__decoder';
import { getCookie } from 'Services/cookie/core__cookies';
import {
  activeCategoryFilter,
  changeMarketColumnSelection,
} from './core__events-actions';
import { getSelectedMarketDropdown } from './core__events-utils';

import { TranslationsProvider } from 'Services/translations/core__translations';
import { checkEventsHeaderAppActive } from 'Services/core__services';
import {
  translationString,
  marketsToTranslate,
} from './core__events-translations';
import { Dummy } from 'Services/core__dummy';
export const Translations = new TranslationsProvider('EventsApp');

class EventsApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations.getAll.bind(
        properties.Translations
      );
      return;
    }
    if (this.appConfig) {
      this.appConfig.eventsHeaderActive = checkEventsHeaderAppActive(
        this.appSection
      );
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp(this.cookies, false); //from client side
    Translations.setAll(data);

    //check if this app was 404 from server
    let notFound = null;
    try {
      const id = `404-error-${this?.appConfig?.appid}`;
      notFound = this.appSection.querySelector('[id="' + id + '"]');
    } catch (e) {
      //no handling needed
    }

    const jsx = (
      <ReduxProvider
        store={createStore(data || { data: { cookies: this.cookies } })}
      >
        <ReactInstance notFound={notFound} />
      </ReduxProvider>
    );
    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }
  /**
   *
   * @param {Object} cookies, req.cookies object
   */
  getReactApp(cookies) {
    if (!this.appConfig) {
      return Dummy;
    }
    this.url = null;

    const mapStateToProps = state => {
      const activeTabCategory = state.data.activeTabCategory;
      const oddsFormat = cookies
        ? cookies['selectedOdds']
        : getCookie('selectedOdds');
      const catNameTranslated = state.data.catNameTranslated;
      return {
        activeTabCategory,
        state: state.data,
        url: this.url,
        appConfig: {
          ...state.data.appConfig,
          ...this.appConfig,
        },
        oddsFormat: oddsFormat,
        selectedMarkets: getSelectedMarketDropdown(state.data, this.appConfig),
        pageLayoutIsAmerican: state.data.pageLayoutIsAmerican,
        statusCode: state.data.statusCode,
        categoriesFetched: state.data.categories
          ? Object.keys(state.data.categories)
          : state.data.categoriesFetched,
        catNameTranslated: catNameTranslated,
      };
    };

    const mapDispatchToProps = {
      url: this.url,
      fetchData,
      commonFunction,
      changeMarketColumnSelection,
      activeCategoryFilter,
      getSubcatRef,
    };

    EventsAppReact.serverFetch = {
      url: this.url,
      fetchData,
      createStore,
      strToTranslate: translationString,
      marketsToTranslate,
    };

    return connect(mapStateToProps, mapDispatchToProps)(EventsAppReact);
  }
}

export default EventsApp;
