import styled from 'styled-components';
import { GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const StyledRow = styled.div`
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  padding: 1em;
  display: flex;
  align-items: center;
  border-radius: 4px 4px 0 0;
`;

export const StyledCatName = styled.div`
  font-weight: 800;
  color: ${({ theme }) => (theme.dark ? FONT.light : FONT.primary)};
  flex: 3;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: 2;
  }
`;

export const StyledMarketName = styled.div`
  font-size: 12px;
  flex: ${props => (!props.americanLayout ? '2' : '1')};
  @media (max-width: ${BREAKPOINT_S}) {
    flex: ${props => (!props.americanLayout ? '1 auto' : '1')};
  }
`;

export const SMN = styled.h2`
  font-size: 12px;
  text-align: center;
`;
