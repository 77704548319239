import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { emitSelectionIdForBetSlip } from '../../../core__eventsFunction';
import { Market, Teams, EventDate } from './EventsTableRow';
import {
  EventName,
  EventMarket,
  EventsRow,
  MoreMarketsRow,
  RightArrow,
  StyledAnchor,
  EmptyAmericanMarket,
  EventFixture,
  StyledAnchorArrow,
} from 'UI/apps/EventsApp/EventsTableRow';
import EA from 'UI/globals/ExpandArrow';
import { useSelector } from 'react-redux';
import { MarketWithinRow } from './EventsTableRow/core__market-within-row';
import { SiteURL } from 'Services/core__site-url';
import { SportsbookUrlService } from 'Services/url-amendments/core__url-amendments';
import { Constants } from '../../../../core__events-constants';

/**
 * component to render single row of an event with selection buttons
 * @param {Object} event single object cotaining event data `event`
 * @param {Array} marketTypes selected markets in headers `marketTypes`
 * @param {Array} betslipSelections array of selection ids to be hightlighted `betslipSelections`
 * @param {Object} subcat object of subcategory to which this event belongs to `subcat`
 * @param {Object} category object of category to which this event belongs to `category`
 * @param {Object} selectionsToRender object of selections references to render for each market `selectionsToRender`
 */
export const EventsTableRow = ({
  event,
  marketTypes,
  betslipSelections,
  subcat,
  category,
  americanLayout,
  americanMarkets,
  oddsFormat,
  maxMarketsToShow,
  pageLayoutIsAmerican,
  showMoreMarketRow,
  selectionHeaders,
  onVirtuals,
}) => {
  const rowRef = useRef(null);
  const { soccerRedirect, urlAmendments } = useSelector(state => ({
    soccerRedirect: state?.data?.appConfig?.soccerredirect,
    urlAmendments: state?.data?.appConfig?.urlamendments,
  }));
  const [toDisplaySelection, setToDisplaySelection] = useState(
    event.toDisplaySelection
  );
  const [toDisplayMarket, setToDisplayMarket] = useState(event.toDisplayMarket);

  const placeBet = selectionId => {
    emitSelectionIdForBetSlip(selectionId, '', event.providerRef);
  };
  const [selectionIds, setSelectionIds] = useState(betslipSelections);
  useEffect(() => {
    setSelectionIds(betslipSelections);
  }, [betslipSelections]);

  let url = soccerRedirect
    ? `${SiteURL.path}/sportsbook/${soccerRedirect}/${subcat.ref}/${event.id}/`
    : `${SiteURL.path}/sportsbook/${category.ref}/${subcat.ref}/${event.id}/`;

  if (urlAmendments) {
    const urlData = {
      categoryRef: soccerRedirect ?? category.ref,
      eventName: event.name,
      eventId: event.id,
      subcategoryId: event.subcatId,
      subcategoryName: event.subcatName,
    };
    url = SportsbookUrlService.createUrlForTheBrowser(urlData);
  }

  useEffect(() => {
    setToDisplayMarket(event.toDisplayMarket);
    setToDisplaySelection(event.toDisplaySelection);
  }, [event]);

  //max market loop
  const maxMarketsToShowList = Array(maxMarketsToShow).fill();
  let FixtureWrapper = <div />;
  if (EventFixture) {
    FixtureWrapper = EventFixture;
  }

  const [embeddedMarketApp, setEmbeddedMarketApp] = useState(false);

  return (
    <div ref={rowRef}>
      <EventsRow className={'row'} americanLayout={americanLayout}>
        <FixtureWrapper>
          {showMoreMarketRow && (
            <EventDate
              category={category}
              event={event}
              pageLayoutIsAmerican={pageLayoutIsAmerican}
            />
          )}
          <EventName>
            <StyledAnchor href={onVirtuals ? false : url}>
              <Teams
                event={event}
                americanLayout={americanLayout}
                category={category}
              />
            </StyledAnchor>
          </EventName>
        </FixtureWrapper>
        {/* hear */}
        {maxMarketsToShowList.map((v, i) => {
          const marketType = marketTypes[i];

          let availableMarketType =
            marketType &&
            marketType
              .split(',')
              .filter(mT => toDisplayMarket && toDisplayMarket[mT]); //since ther are grouped markets, that's why this code
          // multiple market type for decimal provider
          if (
            category?.ref === Constants.CRICKET_REF &&
            event?.providerRef === Constants.DECIMAL_PROVIDER &&
            availableMarketType &&
            availableMarketType.length > 1
          ) {
            availableMarketType = [availableMarketType[0]];
          }
          const market =
            toDisplayMarket && toDisplayMarket[availableMarketType];

          let marketActive = true;

          if (
            (market && market.active === false) ||
            event.state === 'COMPLETED'
          ) {
            marketActive = false;
          }

          return (
            <Market
              categoryRef={category.ref}
              eventName={event.name}
              marketId={market?.id}
              marketType={marketType}
              americanLayout={americanLayout}
              toDisplaySelection={toDisplaySelection}
              selectionIds={selectionIds}
              americanMarkets={americanMarkets}
              oddsFormat={oddsFormat}
              marketActive={marketActive}
              placeBet={placeBet}
              key={i}
              selectionHeaders={
                selectionHeaders && selectionHeaders[marketType]
              }
              toDisplayMarket={market}
              onVirtuals={onVirtuals}
            />
          );
        })}
        {showMoreMarketRow &&
          (event.marketCnt > 0 ? (
            <EventMarket americanLayout={americanLayout}>
              <StyledAnchor href={url}>+{event.marketCnt}</StyledAnchor>
            </EventMarket>
          ) : (
            <EmptyAmericanMarket></EmptyAmericanMarket>
          ))}
        {onVirtuals && (
          <div onClick={() => setEmbeddedMarketApp(!embeddedMarketApp)}>
            <EA expanded={embeddedMarketApp} size="8" onVirtuals={onVirtuals} />
          </div>
        )}
      </EventsRow>

      {embeddedMarketApp && <MarketWithinRow eventId={event.id} />}
      {!showMoreMarketRow && (
        <MoreMarketsRow americanLayout={americanLayout}>
          <EventDate
            category={category}
            event={event}
            pageLayoutIsAmerican={pageLayoutIsAmerican}
          />

          {event.marketCnt > 0 && (
            <EventMarket>
              {(onVirtuals && (
                <div onClick={() => setEmbeddedMarketApp(!embeddedMarketApp)}>
                  {event.marketCnt}
                  <RightArrow americanLayout={americanLayout} />
                </div>
              )) || (
                <StyledAnchorArrow href={url}>
                  {event.marketCnt}
                  <RightArrow americanLayout={americanLayout} />
                </StyledAnchorArrow>
              )}
            </EventMarket>
          )}
        </MoreMarketsRow>
      )}
    </div>
  );
};

EventsTableRow.propTypes = {
  betslipSelections: PropTypes.array,
  category: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.string,
  }),
  event: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    scheduledStart: PropTypes.number,
    state: PropTypes.string,
    toDisplayMarket: PropTypes.object,
    toDisplaySelection: PropTypes.object,
    marketCnt: PropTypes.number,
    providerRef: PropTypes.string,
    subcatId: PropTypes.number,
    subcatName: PropTypes.string,
  }),
  marketTypes: PropTypes.array,
  subcat: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.string,
  }),
  selectionsToRender: PropTypes.object,
  americanLayout: PropTypes.bool,
  americanMarkets: PropTypes.object,
  oddsFormat: PropTypes.string,
  inPlayTimer: PropTypes.any,
  maxMarketsToShow: PropTypes.any,
  pageLayoutIsAmerican: PropTypes.bool,
  showMoreMarketRow: PropTypes.bool,
  selectionHeaders: PropTypes.object,
  onVirtuals: PropTypes.bool,
};
