import styled, { css } from 'styled-components';
import { BRAND } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const Team = styled.p`
  display: flex;
  padding-right: 10px;
  margin-bottom: ${({ americanLayout }) => (americanLayout ? '8px' : '0')};
  line-height: ${({ americanLayout }) => (americanLayout ? '48px' : '20px')};
`;

export const TeamName = styled.span`
  flex: 1;
  @media (max-width: ${BREAKPOINT_S}) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    min-width: 0;
  }
  padding-left: 6px;
  ${({ isServing }) =>
    isServing &&
    css`
      border-left: 2px solid ${BRAND.favourite};
      padding-left: 4px;
    `}
`;

export const TeamScore = styled(TeamName)`
  flex: 0;
  min-width: fit-content;
`;
