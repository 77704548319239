import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { SCROLL_CAROUSEL_PADDING } from 'UI/globals/colours';

//don't change below css or slider will now work

//overflow is important to make the scroll work, no change
export const CT = styled.div`
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  // Added a local variable to padding, design required to have more space
  // if the scroll padding does not exist it will go back to default "1px"
  padding: ${SCROLL_CAROUSEL_PADDING || '1px'};
  // To hide unneccessary scroll bar in few browsers like firefox
  scrollbar-width: none;
`;

//overflow is important to make the scroll work, no change
const Wrap = styled.div`
  width: 100%;
  ${({ overflow }) =>
    (overflow &&
      css`
        overflow: visible;
      `) ||
    css`
      overflow: hidden;
    `}
  position: relative;
`;
const FD = styled.div`
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const DI = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
`;

/**
 *
 * @param {*} children an array of jsx elements to be rendered inside the carousel
 * @param {*} nextArrow an object containing arrow component to be rendered as next arrow
 * @param {*} prevArrow an object containing arrow component to be rendered as prev arrow
 * @param {*} autoplay boolean, to autoplay the slides inside the carousel
 * @param {*} speed number, to define transition speed of the slides
 * @param {*} arrows boolean, flag to display prev/next arrows over the carousel
 * @param {*} rightMargin string, to enable space between two consecutive cards elements inside carousel
 * @param {*} width string, to set width of elements(cards) inside slider
 */
export const ScrollCarousel = ({
  arrows,
  nextArrow,
  prevArrow,
  children,
  movePercent = 100,
  width = 'fit-content',
  rightMargin = 0,
  moveNext,
  movePrev,
  categoryTab,
  overflow,
}) => {
  //dom references
  const wrapper = useRef();
  const inner = useRef();
  const item = useRef();

  //position to start draggging
  const [xPos, setXPos] = useState(null);
  const [showArrows, setShowArrows] = useState(arrows);
  const [clickPreventDiv, setClickPreventDiv] = useState(false);

  //scroll on click of arrows
  const scroll = type => {
    const width = wrapper.current.clientWidth;
    const movement = (width * movePercent) / 100;
    const currentScroll = inner.current.scrollLeft;
    if (type === 'next') {
      inner.current.scrollTo({
        top: 0,
        left: currentScroll + movement,
        behavior: 'smooth',
      });
    } else {
      inner.current.scrollTo({
        top: 0,
        left: currentScroll - movement,
        behavior: 'smooth',
      });
    }
  };

  //to
  const mouseMoveHandler = e => {
    // How far the mouse has been moved
    if (!categoryTab) {
      if (xPos && xPos.mouse > -1) {
        //primary clicked
        const movement = xPos.mouse - e.pageX;
        const currentScroll = xPos.scroll;
        inner.current.scrollTo(currentScroll + movement, 0);
        setClickPreventDiv(true);
      }
    }
  };

  //don't show arrows if content is less than
  useEffect(() => {
    if (children && children.length) {
      const innnerContentWidth =
        (item?.current?.offsetWidth +
          parseInt(item.current.style.marginRight)) *
        children.length;

      if (innnerContentWidth <= wrapper.current.clientWidth) {
        setShowArrows(false);
      } else {
        setShowArrows(arrows);
      }
    }
  }, [children]); // eslint-disable-line react-hooks/exhaustive-deps

  //to call next prev from outside
  useEffect(() => {
    if (movePrev) {
      scroll('prev');
    }
  }, [movePrev]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (moveNext) {
      scroll('next');
    }
  }, [moveNext]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Wrap ref={wrapper} overflow={overflow}>
      {showArrows &&
        React.cloneElement(prevArrow, {
          onClick: () => {
            scroll('prev');
          },
        })}
      <CT
        ref={inner}
        onMouseMoveCapture={mouseMoveHandler}
        onMouseDown={e => {
          setXPos({ mouse: e.pageX, scroll: inner.current.scrollLeft });
        }}
        onMouseUp={() => {
          setXPos(null);
          setClickPreventDiv(false);
        }}
        onMouseLeave={() => {
          setXPos(null);
          setClickPreventDiv(false);
        }}
      >
        {clickPreventDiv && <DI />}
        {children &&
          children.length &&
          children.map((child, index) => {
            return (
              <FD
                ref={(index === 0 && item) || null}
                style={{ width: width, marginRight: `${rightMargin}px` }}
                key={index}
                className="slick-slide"
              >
                {child}
              </FD>
            );
          })}
      </CT>
      {showArrows &&
        React.cloneElement(nextArrow, {
          onClick: () => {
            scroll('next');
          },
        })}
    </Wrap>
  );
};

ScrollCarousel.propTypes = {
  arrows: PropTypes.bool,
  categoryTab: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  movePercent: PropTypes.number,
  nextArrow: PropTypes.any,
  prevArrow: PropTypes.any,
  rightMargin: PropTypes.number,
  width: PropTypes.string,
  moveNext: PropTypes.any,
  movePrev: PropTypes.any,
  showArrows: PropTypes.any,
  overflow: PropTypes.bool,
};
