import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { EventsTableRow } from './EventsTableRow';
import * as Categories from '../../../../core__events-markets';
import {
  League,
  MarketRefLabel,
  MarketWagerLabel,
  MarketRefLabelContainer,
  EventMarketEmpty,
} from 'UI/apps/EventsApp/EventsTableBodySection';
import {
  Constants,
  SH_SELECTIONS,
  SH_SELECTIONS_REVERSE,
} from '../../../../core__events-constants';
import project from '../../../../../../../project';
import { Translations } from '../../../../core__events-app';
import replaceAnd from 'Services/translations/core__replaceAnd';

/**
 * this component contains subcat header and rows of table for each subcategory
 * @param {Object} subcat object of subcategory contains events array and subcategory details `subcat`
 * @param {Object} marketTypes array of market types to be displayed in subheader `marketTypes`
 * @param {Object} maxMarketsToShow number of columns to show `maxMarketsToShow`
 * @param {Object} betslipSelections array of selection ids whose betslip is selected `betslipSelections`
 * @param {Object} category object of category `category`
 * @param {Object} selectionsToRender object of selection refs `selectionsToRender`
 */
export const EventsTableBodySection = ({
  subcat,
  marketTypes,
  maxMarketsToShow,
  betslipSelections,
  category,
  selectionsToRender,
  americanLayout,
  oddsFormat,
  numberOfRowsToShow,
  pageLayoutIsAmerican,
  showOSBodySection,
  onVirtuals,
}) => {
  const [collapsed, setCollapsed] = useState(
    subcat.collapsed ? subcat.collapsed : false
  );
  const selectionHeaders = __getSelectionHeaders(
    selectionsToRender,
    marketTypes
  );
  const [tableRows, setTableRows] = useState(
    __getTableRows(
      subcat,
      marketTypes,
      maxMarketsToShow,
      betslipSelections,
      category,
      americanLayout,
      oddsFormat,
      numberOfRowsToShow,
      pageLayoutIsAmerican,
      selectionHeaders,
      onVirtuals
    )
  );

  const toggleData = () => {
    setCollapsed(!collapsed);
  };
  //for csr
  useEffect(() => {
    const rows = __getTableRows(
      subcat,
      marketTypes,
      maxMarketsToShow,
      betslipSelections,
      category,
      americanLayout,
      oddsFormat,
      numberOfRowsToShow,
      pageLayoutIsAmerican,
      selectionHeaders,
      onVirtuals
    );

    setTableRows(rows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    subcat.events,
    betslipSelections,
    marketTypes,
    oddsFormat,
    numberOfRowsToShow,
    maxMarketsToShow,
  ]);

  useEffect(() => {
    setCollapsed(subcat.collapsed);
  }, [subcat.collapsed]);

  return (
    Array.isArray(tableRows) &&
    tableRows.length > 0 && (
      <React.Fragment>
        <League
          americanLayout={americanLayout}
          onClick={toggleData}
          className="tgl-btn"
        >
          <MarketRefLabel>{replaceAnd(subcat.name)}</MarketRefLabel>
          {!collapsed &&
            selectionHeaders &&
            marketTypes &&
            Array(maxMarketsToShow)
              .fill()
              .map((v, i_typeref) => {
                const typeref = marketTypes[i_typeref];

                if (americanLayout) {
                  //render american market names
                  let label = '';
                  try {
                    label =
                      Categories[category.ref]['american_market'][typeref];
                  } catch (error) {
                    label = typeref;
                  }
                  return (
                    <MarketRefLabelContainer
                      americanLayout={americanLayout}
                      key={i_typeref}
                      onVirtuals={onVirtuals}
                    >
                      <MarketWagerLabel>
                        {Translations.get(
                          `text.${label.toLowerCase().split(' ').join('')}`
                        )}
                      </MarketWagerLabel>
                    </MarketRefLabelContainer>
                  );
                } else {
                  //render selection names
                  let labelsObj = selectionHeaders[typeref] || {}; //empty required for length check
                  if (typeref === Constants.DOUBLE_CHANCE) {
                    const newLabelObj = {};
                    Object.entries(labelsObj).map(([key, value]) => {
                      newLabelObj[`${Constants.DOUBLE_CHANCE}.${key}`] = value;
                    });

                    labelsObj = newLabelObj;
                  }
                  if (
                    !(
                      marketTypes.includes(Constants.THREEBALL) ||
                      marketTypes.includes(Constants.TWOBALL)
                    )
                  ) {
                    return (
                      <MarketRefLabelContainer
                        key={i_typeref}
                        onVirtuals={onVirtuals}
                      >
                        {labelsObj &&
                          Object.keys(labelsObj).map((selection, i) => {
                            return (
                              <MarketWagerLabel key={i}>
                                {Translations.get(
                                  `text.${selection.toLowerCase()}`
                                )}
                              </MarketWagerLabel>
                            );
                          })}
                      </MarketRefLabelContainer>
                    );
                  }
                }
              })}
          {showOSBodySection ||
            (onVirtuals && (
              <EventMarketEmpty
                americanLayout={americanLayout}
              ></EventMarketEmpty>
            ))}
        </League>
        {!collapsed && tableRows}
      </React.Fragment>
    )
  );
};

EventsTableBodySection.propTypes = {
  betslipSelections: PropTypes.array,
  category: PropTypes.object,
  marketTypes: PropTypes.array,
  maxMarketsToShow: PropTypes.number,
  subcat: PropTypes.shape({
    collapsed: PropTypes.bool,
    events: PropTypes.array,
    name: PropTypes.string,
  }),
  selectionsToRender: PropTypes.object,
  americanLayout: PropTypes.bool,
  oddsFormat: PropTypes.string,
  numberOfRowsToShow: PropTypes.number,
  pageLayoutIsAmerican: PropTypes.bool,
  showOSBodySection: PropTypes.bool,
  onVirtuals: PropTypes.bool,
};

/**private functions */
function __getTableRows(
  subcat,
  marketTypes,
  maxMarketsToShow,
  betslipSelections,
  category,
  americanLayout,
  oddsFormat,
  numberOfRowsToShow,
  pageLayoutIsAmerican,
  selectionHeaders,
  onVirtuals
) {
  const rows = [];
  subcat.events &&
    subcat.events.forEach((event, i_event) => {
      if (numberOfRowsToShow > i_event || !numberOfRowsToShow) {
        event.subcatId = subcat.subcatId;
        event.subcatName = subcat.subcatName;
        rows.push(
          <EventsTableRow
            key={event.id}
            event={event}
            marketTypes={marketTypes}
            maxMarketsToShow={maxMarketsToShow}
            betslipSelections={betslipSelections}
            subcat={subcat}
            category={category}
            americanLayout={americanLayout}
            americanMarkets={
              (americanLayout &&
                Categories[category.ref] &&
                Categories[category.ref]['american_market']) ||
              null
            }
            pageLayoutIsAmerican={pageLayoutIsAmerican}
            oddsFormat={oddsFormat}
            selectionHeaders={selectionHeaders}
            onVirtuals={onVirtuals}
          />
        );
      }
    });
  return rows;
}

function __getSelectionHeaders(selectionsByMarket, marketTypes) {
  const result = {};
  marketTypes.forEach(typeref => {
    let labelsObj = selectionsByMarket[typeref] || {}; //empty required for length check

    typeref &&
      typeref.indexOf(',') !== -1 &&
      typeref.split(',').forEach(mktRef => {
        if (
          selectionsByMarket[mktRef] &&
          Object.keys(labelsObj).length <
            Object.keys(selectionsByMarket[mktRef]).length
        ) {
          labelsObj = selectionsByMarket[mktRef];
        }
      });

    //for SH markets check O/U
    if (
      labelsObj &&
      Object.keys(labelsObj).length > 0 &&
      Object.keys(labelsObj).every(sRef => SH_SELECTIONS[sRef])
    ) {
      if (project.slovakian) {
        labelsObj = SH_SELECTIONS_REVERSE;
      } else {
        labelsObj = SH_SELECTIONS;
      }
    } else {
      //just for sorting home draw away, don't change any logic here
      const updatedLabelObject = {};
      if (labelsObj[Constants.HOME]) {
        updatedLabelObject[Constants.HOME] = undefined;
      }
      if (labelsObj[Constants.DRAW]) {
        updatedLabelObject[Constants.DRAW] = undefined;
      }
      if (labelsObj[Constants.EITHER]) {
        updatedLabelObject[Constants.EITHER] = undefined;
      }
      if (labelsObj[Constants.AWAY]) {
        updatedLabelObject[Constants.AWAY] = undefined;
      }
      //for btts selection sequence
      if (labelsObj[Constants.YES]) {
        updatedLabelObject[Constants.YES] = undefined;
      }
      if (labelsObj[Constants.NO]) {
        updatedLabelObject[Constants.NO] = undefined;
      }

      labelsObj = { ...updatedLabelObject, ...labelsObj };
    }

    result[typeref] = labelsObj;
  });

  //sort home draw away
  return result;
}
