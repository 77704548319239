import styled from 'styled-components';
//category tabs container
export const SC = styled.div`
  display: flex;
  padding: 8px;
  margin-left: -8px;
  margin-bottom: -8px;
  height: 64px;
  .slick-track {
    min-width: max-content;
  }
  .category-slider {
    max-width: 100%;
  }
  .slick-slide {
    margin: 0;
    div > a {
      opacity: 1;
    }
  }
`;
