import theme from 'UI/globals/theme';
import styled from 'styled-components';
import { FUNCTIONAL, GREYS, FONT } from 'UI/globals/colours';

export const SW = styled.div`
  position: relative;
  &:last-child {
    margin-right: 8px;
  }
  &:first-child {
    margin-right: 0;
  }
  margin-bottom: ${({ extraBottomMargin }) => extraBottomMargin && `24px`};
  caret-color: transparent;
`;

export const Label = styled.div`
  font-family: ${theme.fontFamily};
  font-size: 14px;
  margin-bottom: 8px;
  color: ${props => (props.theme.dark ? GREYS.white : GREYS.black)};
`;

export const Info = styled.p`
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin-top: 4px;
  color: ${FUNCTIONAL.negative};
  padding-left: 8px;
`;

//native selector hidden over react-select, cover it entirely
export const NS = styled.select`
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;

export const BD = styled.div`
  font-weight: bold;
  color: ${FONT.dark};
`;
