import { GREYS } from 'UI/globals/colours';
import styled, { css } from 'styled-components';
import { BRAND } from 'UI/globals/colours';
export const ED = styled.p`
  color: ${GREYS.silver};
  font-size: 12px;
  &:first-child {
    flex: 1;
    margin-left: 8px;
  }
  ${({ isTennis, isTableTennis, isVolleyball, isDarts }) =>
    (isTennis || isTableTennis || isVolleyball, isDarts) &&
    css`
      display: flex;
      span {
        margin-right: 16px;
      }
    `}
`;
export const OSDW = styled.div`
  color: ${BRAND.primary};
  font-size: 12px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 4px;
`;
export const Live = styled.div`
  border: 1px solid ${BRAND.primary};
  border-radius: 4px;
  margin: 8px 36px 8px 20px;
`;
