import { SC } from 'CORE__UI/apps/EventsApp/core__categoryTabs';
import styled from 'styled-components';
import { BREAKPOINT_S } from '../../globals/breakpoints';
const SC_JN = styled(SC)`
  @media (max-width: ${BREAKPOINT_S}) {
    height: 76px;
    margin-bottom: 0;
  }
`;
export { SC_JN as SC };
