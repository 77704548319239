import styled, { css } from 'styled-components';
import { GREYS, BRAND, FONT, SHADOW } from 'UI/globals/colours';

export const StyledCategoryTab = styled.a`
  font-family: 'Nunito Sans', sans-serif;
  height: 48px;
  padding: 0 23px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  font-size: 14px;
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  box-shadow: ${SHADOW};
  color: ${({ active, theme }) =>
    active
      ? theme.dark
        ? FONT.light
        : FONT.primary
      : theme.dark
      ? FONT.light
      : FONT.dark};
  cursor: pointer;
  white-space: nowrap;
  opacity: 1;
  transition: all 250ms ease-in-out;
  @media (hover) {
    &:hover {
      color: ${FONT.primary};
    }
  }
  max-width: 20em;
  ${({ active }) =>
    active &&
    css`
      font-weight: 800;
      color: ${FONT.primary};
    `}
  position:relative;
  border-radius: 4px;
  ${({ theme }) =>
    theme.dark &&
    css`
      color: ${({ active }) => (active ? GREYS.white : GREYS.doveD)};
      background-color: ${GREYS.silverD};
      margin-right: 8px;
      padding: 0 16px 0 12px;
      @media (hover) {
        &:hover {
          color: ${BRAND.primary};
        }
      }
    }
    `}
`;

export const LabelSpan = styled.span`
  &:before {
    display: block;
    content: attr(title);
    font-weight: ${({ fontBold }) => (fontBold ? fontBold : 0)};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
`;

export const C = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 4px;
  background-color: ${({ active, theme }) =>
    active ? BRAND.secondary : theme.dark ? GREYS.silver : BRAND.tertiary};
  font-size: 10px;
  border-radius: 0 0 0 4px;
  ${({ theme }) =>
    theme.dark &&
    css`
      background-color: ${({ active }) =>
        active ? BRAND.favourite : GREYS.doveD};
      color: ${({ active }) => (active ? GREYS.black : GREYS.silver)};
      font-weight: 400;
    `}
`;
