import styled, { css } from 'styled-components';
import { Select } from '../../../globals/Select/core__select';
import theme from 'UI/globals/theme';
import { GREYS, FONT } from 'UI/globals/colours';

export const StyledSelect = styled(Select)`
  color: ${({ theme }) => (theme.dark ? FONT.light : FONT.dark)};
  font-family: ${theme.fontFamily};
  font-size: 12px;
  margin: 0;
  flex: 1;

  div[class*='singleValue'] {
    cursor: pointer;
    border-color: ${GREYS.white};
    color: ${({ theme }) => (theme.dark ? FONT.light : FONT.dark)};

    top: unset;
    transform: unset;
    position: unset;
    max-width: 100%;
    text-overflow: clip;
  }

  div[class*='singleValue'] {
    &:after {
      margin-left: 8px;
      content: '';
      border: solid ${GREYS.white};
      color: ${({ theme }) => (theme.dark ? FONT.light : FONT.dark)};

      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      position: relative;
      top: -3px;
      margin-right: 4px;
    }
  }

  ${({ theme }) =>
    theme.dark &&
    css`
      div[class*='singleValue'] {
        color: ${GREYS.white};
        &:after {
          border-color: ${GREYS.white};
        }
      }
    `}
`;
