import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { CategoryTabs } from './CategoryTabs';
import { EventsTable } from './EventsTable';
import { Constants } from '../../core__events-constants';
import * as marketConf from '../../core__events-markets';
import { Translations } from '../../core__events-app';
import { EventsTableContainer } from 'UI/apps/EventsApp/EventsTableContainer';

/**
 * container of events app all theme component and interacts with store to get data and dispatch
 * @param {Boolean} active boolena to identify if current app is in display
 * @param {Object} state redux global state of event application
 * @param {Function} dispatch redux dispatch
 * @param {Function} deactivateHeader a function to deactivate event headers app link if no data is found
 * @param {Boolean} categorytab a to identify if
 */
export const EventsAppContainer = ({
  active,
  state,
  categorytab,
  oddsFormat,
  selectedMarkets,
  onChangeMarketDropdown,
  columns,
  getActiveCategoryData,
  displayAmount,
  lazyloadlimit,
  pageLayoutIsAmerican,
  categoriesFetched,
  disableDropdown,
  catRefForOut,
  fightWinner,
  catRefWithDropdown = '',
  onVirtuals,
  showTitle,
}) => {
  const { activeTabCategory, betslipSelections, categories } = state;

  /**
   * render
   */
  return active ? (
    <React.Fragment>
      {categories && categorytab && (
        <CategoryTabs
          getActiveCategoryData={getActiveCategoryData}
          categories={categories}
          activeTabCategory={activeTabCategory}
          eventsCount={state.events_count}
          catRefForOut={catRefForOut}
        />
      )}

      {categoriesFetched &&
        categoriesFetched.map(categoryRef => {
          const data = state[categoryRef];
          if (data) {
            const { events, markets_fetched, americanLayout } = data;

            let render = false;
            //if tabbed categories are set in CMS then load only active from selected, else load all
            if (
              !categorytab ||
              (categorytab && categoryRef === activeTabCategory.ref)
            ) {
              render = true;
              showTitle(true);
            }
            let cols = columns && parseInt(columns);
            if (americanLayout) {
              cols = Constants.DEFAULT_COLUMNS_AMERICAN;
            }

            //american are fixed, TO DO move this code to a core file, moved here for ssr translations
            let dropdown;
            if (!americanLayout) {
              const mktConf = { ...marketConf };
              //in case if any new market missing, just set fetched markets in conf
              if (!mktConf[categoryRef] && markets_fetched) {
                mktConf[categoryRef] = {
                  markets: Object.keys(markets_fetched),
                };
              }
              let marketsForCatref = mktConf[categoryRef]['markets'];
              if (
                disableDropdown &&
                catRefWithDropdown.indexOf(categoryRef) === -1
              ) {
                marketsForCatref = marketsForCatref.slice(0, cols); //only first 2 or 3 or 1 based on col
              }
              dropdown = marketsForCatref.reduce((result, mkt) => {
                result[mkt] = Translations.get(mkt.split(',')[0], 'market');
                return result;
              }, {});

              //if dropdown does not include fetched market, case possible when default conf is loaded
              //update translations from API in the same loop
              markets_fetched &&
                Object.keys(markets_fetched).forEach(mkt => {
                  //in case if fetched market is missing in dropdown config
                  if (Object.keys(dropdown).join(',').indexOf(mkt) === -1) {
                    dropdown[mkt] = markets_fetched[mkt]; //it's translated value
                  }
                  //update translations in dropdown from fetched market
                  if (dropdown[mkt]) {
                    dropdown[mkt] = markets_fetched[mkt];
                  }
                });
            }
            return (
              <React.Fragment key={categoryRef}>
                {render && (
                  <EventsTableContainer>
                    <EventsTable
                      events={events}
                      activeTabCategory={categoryRef}
                      betslipSelections={betslipSelections}
                      selectedmarketDropdown={
                        selectedMarkets && selectedMarkets[categoryRef]
                      }
                      columns={cols}
                      americanLayout={americanLayout}
                      oddsFormat={oddsFormat}
                      onChangeMarketDropdown={onChangeMarketDropdown}
                      displayAmount={displayAmount || lazyloadlimit}
                      lazyloadlimit={lazyloadlimit || displayAmount}
                      dropdown={dropdown}
                      pageLayoutIsAmerican={pageLayoutIsAmerican}
                      getActiveCategoryData={getActiveCategoryData}
                      eventsCount={state.events_count}
                      disableDropdown={
                        disableDropdown &&
                        catRefWithDropdown.indexOf(categoryRef) === -1
                      }
                      catRefWithDropdown={catRefWithDropdown}
                      fightWinner={fightWinner}
                      onVirtuals={onVirtuals}
                    />
                  </EventsTableContainer>
                )}
              </React.Fragment>
            );
          }
        })}
    </React.Fragment>
  ) : (
    <></>
  );
};

EventsAppContainer.propTypes = {
  active: PropTypes.bool,
  dispatch: PropTypes.func,
  state: PropTypes.shape({
    activeTabCategory: PropTypes.shape({
      ref: PropTypes.string,
    }),
    betslipSelections: PropTypes.array,
    categories: PropTypes.object,
    events_count: PropTypes.object,
  }),
  categorytab: PropTypes.any,
  oddsFormat: PropTypes.string,
  selectedMarkets: PropTypes.any,
  onChangeMarketDropdown: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  getActiveCategoryData: PropTypes.func,
  displayAmount: PropTypes.any,
  lazyloadlimit: PropTypes.any,
  pageLayoutIsAmerican: PropTypes.bool,
  dropdown: PropTypes.object,
  categoriesFetched: PropTypes.array,
  disableDropdown: PropTypes.any,
  catRefForOut: PropTypes.string,
  fightWinner: PropTypes.bool,
  catRefWithDropdown: PropTypes.string,
  onVirtuals: PropTypes.bool,
  showTitle: PropTypes.func,
};

export const mapDispatchToProps = dispatch => {
  return { dispatch };
};

export default connect(null, mapDispatchToProps)(EventsAppContainer);
