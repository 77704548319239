import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import getComposeEnhancers from 'Services/redux/core__redux-dev-tools';
import { getRequest } from 'Services/http/core__axios';
import {
  initialStateFromRawData,
  updateBetslipsSelection,
  handleDataNotFound,
  updateEventCount,
  translatedCatName,
} from './core__events-actions';
import { Constants } from './core__events-constants';
import { fetchEventsCount, fetchEventsData } from './core__events-fetch-data';
import { dataReducer } from './core__events-reducer';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

export const reducer = combineReducers({
  data: dataReducer,
});

/**
 * this is a thunk middleware function, for initial api call and dispatch to store
 * @param {Array | String} url, array if multiple requests are to be made
 * @param {Request} nodeRequest null or node api from ssr
 * @param {Object} appConfig event app parameters
 * @param {Boolean} updateMetaData boolean to update only category data or data overall
 */
export const fetchData = (
  url,
  nodeRequest,
  appConfig,
  updateMetaData = true
) => async dispatch => {
  const requester = !nodeRequest ? getRequest : nodeRequest;

  let catref = null;
  if (appConfig.categorytab && updateMetaData) {
    const eventsCountData = await fetchEventsCount(appConfig, requester);

    if (eventsCountData.category && eventsCountData.category.length > 0) {
      const action = updateEventCount({
        ...eventsCountData,
        appConfig: appConfig,
      });
      dispatch(action);

      catref = action.data && Object.keys(action.data)[0];
    }
  }

  return fetchEventsData(appConfig, requester, catref)
    .then(res => {
      if (res?.statusCode === 500 || res?.statusCode === 404) {
        return dispatch(handleDataNotFound(res?.statusCode, appConfig));
      } else {
        if (Array.isArray(res.category) && res.category.length > 0) {
          const catName = res.category[0].name;
          dispatch(translatedCatName(catName)); // Translated value stored in state
        }
        return dispatch(
          initialStateFromRawData({
            ...res,
            appConfig: appConfig,
            updateMetaData,
          })
        ); //we'll also keep appconfig stored in app global state
      }
    })
    .catch(e => {
      return dispatch(handleDataNotFound(e?.response?.status, appConfig));
    });
};

//this is a thunk function middleware which could be used for any common
/**
 * this is a multipurpose store attached function can be used in any functionality
 * @param {*} data , could be anything with type as parameter to identify functionality
 */
export const commonFunction = fnData => {
  switch (fnData.type) {
    case Constants.UpdateBetslipsSelection:
      return dispatch => {
        dispatch(updateBetslipsSelection(fnData.data));
      };
    default:
      break;
  }
};

export const getSubcatRef = (category, subcatId) => dispatch => {
  if (!isNaN(subcatId)) {
    const url = `/fsb-api-rest/bet/category/${category}/id/${subcatId}.fsb`;
    return getRequest(url).then(res => {
      PubSub.emit(PubsubEvents.CATEGORY_UPDATE, res?.category);
      dispatch({
        type: 'STORE_SUBCAT_REF',
        subcatRef: res?.category?.[0]?.subcat?.[0]?.ref,
      });
    });
  }
};

const composeEnhancers = getComposeEnhancers({ name: 'events-store' });

export default initialState =>
  createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware))
  );
