import React, { useEffect, useState } from 'react';
import Select, { createFilter, components } from 'react-select';
import AsyncSelect from 'react-select/async';
import PropTypes from 'prop-types';
import { detectMob } from '../../../core__sportsbook-utils';

import { Dummy } from 'Services/core__dummy';
import {
  SW as SW_LOCAL,
  Label as Label_LOCAL,
  Info as Info_LOCAL,
  NS as NS_LOCAL,
  BD as BD_LOCAL,
} from 'UI/globals/SelectStyles';
import {
  SW as SW_GLOBAL,
  Label as Label_GLOBAL,
  Info as Info_GLOBAL,
  NS as NS_GLOBAL,
  BD as BD_GLOBAL,
} from 'CORE__UI/globals/Select/core__selectStyles';

// in our babel setup;
// if the file we are importing from has not been created, its exports become Dummy() functions
// if the file is created, but the export is not defined there - it will be undefined
const isDummy = suspect => suspect === Dummy;
const SW = !isDummy(SW_LOCAL) ? SW_LOCAL : SW_GLOBAL;
const Label = !isDummy(Label_LOCAL) ? Label_LOCAL : Label_GLOBAL;
const Info = !isDummy(Info_LOCAL) ? Info_LOCAL : Info_GLOBAL;
const NS = !isDummy(NS_LOCAL) ? NS_LOCAL : NS_GLOBAL;
const BD = !isDummy(BD_LOCAL) ? BD_LOCAL : BD_GLOBAL;

const SelectWrapper = props => {
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  const [value, setValue] = useState(props.defaultValue);
  const [options, setOptions] = useState(
    props.defaultValue && props.defaultValue.value === ''
      ? [props.defaultValue].concat(props.options)
      : props.options
  );
  useEffect(() => {
    if (detectMob()) {
      setIsMobileDevice(true);
    }
  }, []);

  // Update initial state on prop value change
  useEffect(() => {
    props.defaultValue ? setValue(props.defaultValue) : setValue('');
  }, [props.defaultValue]);

  useEffect(() => {
    if (!props.loadOptions) {
      if (props.defaultValue && props.defaultValue.value === '') {
        setOptions([props.defaultValue].concat(props.options));
      } else if (
        props.options &&
        props.defaultValue &&
        !props.options.containObject(props.defaultValue)
      ) {
        setOptions([props.defaultValue].concat(props.options)); //case of events dropdown
      } else {
        setOptions(props.options);
      }
    }
  }, [props.options]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = e => {
    if (!e.target) {
      const newValue = { value: e.value, label: e.label };
      newValue && setValue(newValue);
      props.onChange(newValue);
    } else {
      const newValue = options[e.target.selectedIndex];
      newValue && setValue(newValue);
      props.onChange(newValue);
    }
  };

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <BD>...</BD>
      </components.DropdownIndicator>
    );
  };

  return (
    <React.Fragment>
      <SW
        extraBottomMargin={props.extraBottomMargin}
        showCaretColor={props.loadOptions}
      >
        {props.label && <Label>{props.label}</Label>}
        {!props.loadOptions ? (
          <Select
            {...props}
            onChange={onChange}
            components={props.changeDDIndicator && { DropdownIndicator }}
            value={value}
          >
            {props.children}
          </Select>
        ) : (
          <AsyncSelect
            {...props}
            loadOptions={props.loadOptions}
            cacheOptions
            onChange={onChange}
            value={value}
            filterOption={createFilter({ ignoreAccents: false })}
          >
            {props.children}
          </AsyncSelect>
        )}
        {props.error && <Info>{props.error}</Info>}
        {isMobileDevice && props.options && (
          <NS
            onChange={onChange}
            defaultValue={value && value.value}
            disabled={props.isDisabled}
          >
            {options.map(option => {
              return (
                <option key={option?.label} value={option?.value}>
                  {option?.label}
                </option>
              );
            })}
          </NS>
        )}
      </SW>
    </React.Fragment>
  );
};

SelectWrapper.propTypes = {
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  extraBottomMargin: PropTypes.bool,
  loadOptions: PropTypes.func,
  changeDDIndicator: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Array.prototype.containObject = function (object) {
  return this.findIndex(item => item?.value === object?.value) !== -1;
};
export { SelectWrapper as Select };
