import React, { useEffect, useState } from 'react';
import { __getNameForAmericanButton } from '../../../../../core__events-utils';
import { Constants } from '../../../../../core__events-constants';
import { parseDecimalPlaces } from 'Services/global/core__odds-format';
import { getOdds } from 'Services/global/core__american-format';
import PropTypes from 'prop-types';
import { EventsCell, StyledOddsButton } from 'UI/apps/EventsApp/Market';
import { getHandicapFromStr } from 'Services/globalfunctions/core__global-functions';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { setterGetterFromLocalstorage } from 'Services/localstorage/core__localstorage';

export const Market = ({
  marketType,
  americanLayout,
  selectionIds,
  americanMarkets,
  oddsFormat,
  placeBet,
  eventName,
  selectionHeaders,
  toDisplayMarket,
  onVirtuals,
}) => {
  const [market, setMarket] = useState(toDisplayMarket);

  const [betslipSelectionIds, setBetslipSelectionIds] = useState(
    selectionIds || []
  );
  useEffect(() => {
    selectionIds && setBetslipSelectionIds([...selectionIds]);
  }, [selectionIds]);

  let teamReversed = eventName.indexOf(' @ ') !== -1;

  const [selections, setSelections] = useState(__getSelections(market));
  useEffect(() => {
    setSelections(__getSelections(market));
  }, [market]);

  useEffect(() => {
    setMarket(toDisplayMarket);
  }, [toDisplayMarket]);

  const [marketSocketListener, setMarketSocketListener] = useState(null);

  useEffect(() => {
    if (market && market.length > 0) {
      subscribeMarketSocket();
      listenMarketSocket();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const subscribeMarketSocket = async () => {
    const marketIds = market.map(mkt => {
      return mkt.id;
    });
    if (marketIds.length < 50) {
      PubSub.emit(PubsubEvents.SOCKET_MARKET_SUBSCRIBER, marketIds);
    } else {
      let batch = [];
      for (let i = 0; i < marketIds.length; i++) {
        batch.push(marketIds);
        if (i % 50 === 0) {
          PubSub.emit(PubsubEvents.SOCKET_MARKET_SUBSCRIBER, batch);
          batch = [];
          await sleep(1000);
        }
      }
      //for the remaining batch
      if (batch.length > 0) {
        PubSub.emit(PubsubEvents.SOCKET_MARKET_SUBSCRIBER, batch);
      }
    }

    //
  };
  const sleep = async time => {
    return new Promise(resolve => {
      setTimeout(resolve(), time);
    });
  };
  const listenMarketSocket = () => {
    const listener = PubSub.listen(
      PubsubEvents.SOCKET_MARKET_LISTEN,
      wsData => {
        setMarket(markets => {
          let updated = false;
          markets &&
            markets.forEach((mkt, index) => {
              if (mkt.id === wsData.id) {
                //for whole market, if active changes
                if (mkt.active !== wsData.active) {
                  mkt.active = wsData.active;
                  updated = true;
                }

                //if update seelctions
                if (wsData.updatedSelections) {
                  const incomingSelections = [...wsData.selections];
                  incomingSelections.forEach(incoming => {
                    mkt.selection &&
                      mkt.selection.forEach((current, s_index) => {
                        if (current.id === incoming.id) {
                          const currentPrice = current.price
                            ? current.price[0]
                            : {};
                          const updatedPrice = incoming.price.decimal && {
                            ...currentPrice,
                            decimal: incoming.price.decimal,
                            fractional: incoming.price.fractional,
                          };

                          let oddsTrend = null;

                          if (updatedPrice) {
                            const priceDiff =
                              parseFloat(updatedPrice.decimal) -
                              parseFloat(currentPrice.decimal || 0);
                            if (priceDiff > 0) {
                              oddsTrend = Constants.ODDS_INCREASE;
                            } else if (priceDiff < 0) {
                              oddsTrend = Constants.ODDS_DECREASE;
                            }
                          }

                          const newSelection = {
                            ...current,
                            price: updatedPrice
                              ? [updatedPrice]
                              : current.price,
                            active:
                              typeof incoming.active === 'boolean'
                                ? incoming.active
                                : current.active,
                            oddsTrend,
                          };

                          //updating the main variable here
                          mkt.selection[s_index] = newSelection;
                          updated = true;
                        }
                      });
                  });
                }

                markets[index] = mkt;
              }
            });

          if (updated) {
            return [...markets];
          } else {
            return markets; //to prevent re render
          }
        });
      }
    );
    setMarketSocketListener(listener);
  };
  useEffect(() => {
    return () => {
      marketSocketListener && marketSocketListener.unsubscribe();
    };
  }, [marketSocketListener]);

  const { selectionData, active } = selections;

  //total market
  if (
    selectionHeaders &&
    selectionHeaders[Constants.OVER] &&
    teamReversed &&
    americanLayout
  ) {
    teamReversed = false;
  }

  const toggleBetslipSelections = selectionId => {
    const index = betslipSelectionIds.indexOf(selectionId);
    if (index > -1) {
      betslipSelectionIds.splice(index, 1);
    } else {
      betslipSelectionIds.push(selectionId);
    }
    setBetslipSelectionIds([...betslipSelectionIds]);
    placeBet(selectionId);
    if (betslipSelectionIds.length === 1) {
      setterGetterFromLocalstorage({
        keyName: 'retainbettemplate',
        keyValue: ['CHECKED'],
        action: 'set',
      });
    }
  };
  return (
    <EventsCell
      key={marketType}
      reverseMarket={teamReversed}
      americanLayout={americanLayout}
      onVirtuals={onVirtuals}
    >
      {selectionHeaders &&
        Object.keys(selectionHeaders).map((selectionRef, i_selection) => {
          const selection = selectionData && selectionData[selectionRef];
          //button default props
          let size = 'default';
          let layout = 'default';

          let selectionActive = true;
          if (selection && !selection.active) {
            selectionActive = false;
          }

          if (selection) {
            let selected = false;
            if (
              betslipSelectionIds &&
              betslipSelectionIds.indexOf(selection.id) !== -1
            ) {
              selected = true;
            }

            let odds,
              label = '',
              labelPos = null;

            if (americanLayout) {
              //only for american layout
              if (americanMarkets && americanMarkets[marketType]) {
                label = __getNameForAmericanButton(
                  selection,
                  americanMarkets[marketType]
                );
                size = 'small';
                layout = 'handicap';
              }
            }
            //odds value
            if (selection.price && selection.price[0]) {
              if (oddsFormat === Constants.FRACTIONAL) {
                odds = selection.price[0].fractional;
              } else if (oddsFormat === Constants.DECIMAL) {
                odds = parseDecimalPlaces(selection.price[0].decimal);
              } else if (oddsFormat === Constants.AMERICAN) {
                odds = getOdds(selection.price[0].decimal, true);
              }
              // disable selection if price is 0
              if (!selection.price[0].decimal) {
                selectionActive = false;
              }
            }
            if (
              label === '' &&
              (selection.typeRef === Constants.OVER ||
                selection.typeRef === Constants.UNDER)
            ) {
              label = __getNameForAmericanButton(selection, Constants.TOTAL);
              labelPos = 'up';
            }
            if (
              Array.isArray(selection.metadata) &&
              selection.metadata.length > 0
            ) {
              for (const handicapLine of selection.metadata) {
                if (handicapLine.name === 'line') {
                  const getVal = handicapLine.value.replace(' ', '');
                  label = getVal || '';
                  labelPos = getVal && 'up';
                }
              }
            }
            if (label === '') {
              const handicap = getHandicapFromStr(selection.name);
              label = handicap || '';
              labelPos = handicap && 'up';
            }
            if (label === '' && selection.typeRef === 'SSWIN') {
              label = __getNameForAmericanButton(
                selection,
                Constants.SET_WINNER
              );
              labelPos = 'up';
            }
            return (
              <React.Fragment key={i_selection}>
                <StyledOddsButton
                  selected={selected}
                  onClick={() => {
                    active && selectionActive
                      ? toggleBetslipSelections(selection.id)
                      : () => {};
                  }}
                  label={label}
                  odds={odds}
                  oddsTrend={selection.oddsTrend}
                  inactive={!active || !selectionActive}
                  size={size}
                  layout={layout}
                  americanLayout={americanLayout}
                  labelPos={labelPos}
                ></StyledOddsButton>
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={i_selection}>
                <StyledOddsButton
                  layout={layout}
                  inactive={!active || selectionActive}
                  americanLayout={americanLayout}
                  size={size}
                ></StyledOddsButton>
              </React.Fragment>
            );
          }
        })}
    </EventsCell>
  );
};
const __getSelections = toDisplayMarket => {
  let result = {};
  let active = false;
  if (toDisplayMarket) {
    //process multiple markets
    if (toDisplayMarket.length > 1) {
      //process multiple selections
      const { result: rs, index } = __processMultipleMarkets(toDisplayMarket);
      result = rs;

      active = toDisplayMarket[index] && toDisplayMarket[index].active;
    } else if (toDisplayMarket.length === 1) {
      //process multiple selections
      const { result: rs } = __processMultipleSelections(toDisplayMarket[0]);
      result = rs;
      active = toDisplayMarket[0] && toDisplayMarket[0].active;
    }
  }
  return { selectionData: result, active };
};
const __recursiveSetPopulatorByRefs = (
  selectionRefs,
  sortedData,
  allSets = [],
  marketRef
) => {
  const temp = selectionRefs.reduce((acc, ref) => {
    acc[ref] = undefined;
    return acc;
  }, {});
  let k = 0;

  let lastOrdinal = null; //only used in case of SHC market
  let lastHandicap = 0;
  for (let i = 0; i < sortedData.length; i++) {
    if (sortedData[i]) {
      const item = sortedData[i];
      const handicap = parseFloat(getHandicapFromStr(item.name));

      if (marketRef === Constants.SHC && !temp[item.typeRef]) {
        if (!lastOrdinal || lastOrdinal + item.ordinal === 0) {
          lastOrdinal = item.ordinal;
          temp[item.typeRef] = item;
          sortedData[i] = undefined;
          i = 0;
          k++;
        }
      } else if (
        !temp[item.typeRef] &&
        (!lastHandicap || lastHandicap + handicap === 0)
      ) {
        temp[item.typeRef] = item;
        sortedData[i] = undefined;
        lastHandicap = handicap;
        k++;
      }
      if (k === selectionRefs.length) {
        //sort object by key, kill the loop and call the recursive
        allSets.push(Object.values(temp));
        i = 10000000;
        return __recursiveSetPopulatorByRefs(
          selectionRefs,
          sortedData,
          allSets,
          marketRef
        );
      }
    }
  }
  return allSets;
};
const __processMultipleSelections = data => {
  const result = {};
  let index;
  if (data.selection) {
    const selectionRefs = data.selection.reduce((acc, selection) => {
      acc[selection.typeRef] =
        data.typeRef === 'SSWIN' ? data.typeRef : selection.typeRef;
      return acc;
    }, {});

    const allSets = __recursiveSetPopulatorByRefs(
      Object.keys(selectionRefs),
      [...data.selection],
      [],
      data.typeRef //marketRef
    );
    //when only one row is received, applicable to other markets and when lesser data
    if (allSets.length === 1) {
      allSets[0] &&
        allSets[0].forEach(selection => {
          result[selection.typeRef] =
            data.typeRef === 'SSWIN'
              ? { ...selection, name: data.name, typeRef: data.typeRef }
              : { ...selection };
        });
    } else if (allSets.length > 1) {
      //get least deviation one amonth these sets
      const { best, selectedIndex } = __getBestSelection(allSets);
      best.forEach(selection => {
        result[selection.typeRef] =
          data.typeRef === 'SSWIN'
            ? { ...selection, name: data.name, typeRef: data.typeRef }
            : { ...selection };
      });
      index = selectedIndex;
    }
  }
  return { result, index };
};
const __processMultipleMarkets = data => {
  const result = {};
  const allSets = [];
  let index;
  data.forEach(market => {
    if (market.typeRef === 'SSWIN') {
      market.selection.forEach((sel, index) => {
        market.selection[index] = {
          ...sel,
          name: market.name,
          typeRef: market.typeRef,
        };
      });
    }
    market.active ? allSets.push(market.selection) : allSets.push([]);
  });

  if (allSets.length === 0 && data.length > 0) {
    //no active market, just set first market selections
    data[0] &&
      data[0].selection.forEach(selection => {
        result[selection.typeRef] = { ...selection };
      });
  }
  if (allSets.length === 1) {
    allSets[0] &&
      allSets[0].forEach(selection => {
        result[selection.typeRef] = { ...selection };
      });
  } else if (allSets.length > 1) {
    //get least deviation one amonth these sets
    const { best, selectedIndex } = __getBestSelection(allSets);
    best.forEach(selection => {
      result[selection.typeRef] = { ...selection };
    });
    index = selectedIndex;
  }
  return { result, index };
};

const __getBestSelection = data => {
  let lowestSD = 1000;
  let best = data[data.length - 1]; //first row
  let selectedIndex = 0;
  let highestActiveScore = 0;
  //best is when both selection active, and lowest standard deviation

  for (let i = 0; i < data.length; i++) {
    if (data[i] && data[i].length > 0) {
      const sd = __getStandardDeviation(data[i]);

      //0, both inactive, 1 active, 2 both active
      const activeScore = data[i].reduce((acc, curr) => {
        if (curr.active) {
          acc = acc + 1;
        }
        return acc;
      }, 0);

      //if max buttons are active, and sd is lowest

      if (activeScore >= highestActiveScore && sd < lowestSD) {
        lowestSD = sd;
        best = data[i];
        selectedIndex = i;
        highestActiveScore = activeScore;
      } else if (activeScore > highestActiveScore) {
        lowestSD = sd;
        best = data[i];
        selectedIndex = i;
        highestActiveScore = activeScore;
      }
    }
  }
  return { best, selectedIndex };
};

const __getStandardDeviation = data => {
  let arr = [...data];
  // Creating the mean with Array.reduce
  const mean =
    arr.reduce((acc, curr) => {
      const num = curr.price ? curr.price[0].decimal : undefined;
      return acc + parseFloat(num);
    }, 0) / arr.length;

  // Assigning (value - mean) ^ 2 to every array item
  arr = arr.map(k => {
    const num = k.price ? k.price[0].decimal : undefined;
    return (num - mean) ** 2;
  });

  // Calculating the sum of updated array
  const sum = arr.reduce((acc, curr) => acc + curr, 0);

  // Calculating the variance
  const variance = sum / arr.length;

  // Returning the Standered deviation
  return Math.sqrt(variance);
};
Market.propTypes = {
  marketType: PropTypes.string,
  americanLayout: PropTypes.bool,
  selectionsToRender: PropTypes.object,
  toDisplaySelection: PropTypes.object,
  selectionIds: PropTypes.array,
  americanMarkets: PropTypes.object,
  marketActive: PropTypes.bool,
  oddsFormat: PropTypes.string,
  placeBet: PropTypes.func,
  marketId: PropTypes.any,
  eventName: PropTypes.string,
  selectionHeaders: PropTypes.object,
  toDisplayMarket: PropTypes.array,
  onVirtuals: PropTypes.bool,
};
