import React from 'react';
import PropTypes from 'prop-types';
import { EventsTableHeader } from './elements/EventsTableHeader';
import { EventsTableBody } from './elements/EventsTableBody';
import { EventsTable as StyledEvents } from 'UI/apps/EventsApp/EventsTable';

/**
 * this component contains the table for each category
 * @param {*} events object containing all the events for the selected category along with category data
 * @param {*} betslipSelections array of selection ids to show highlighted
 * @param {*} marketDropdown dropdown object for markets key/value with keys=marketrefs `marketDropdown`
 * @param {*} selectedmarketDropdown `current selected markets in dropdown `selectedmarketDropdown`
 * @param {*} marketsFetched markets fetched for selected category
 */
export const EventsTableComponent = ({
  events,
  betslipSelections,
  selectedmarketDropdown,
  americanLayout,
  oddsFormat,
  onChangeMarketDropdown,
  dropdown,
  columns,
  displayAmount,
  lazyloadlimit,
  pageLayoutIsAmerican,
  getActiveCategoryData,
  eventsCount,
  disableDropdown,
  fightWinner,
  onVirtuals,
}) => {
  const eventsToRender = events ? events.group : {};
  const selectionsToRender = events ? events.selections : {};

  const category = events && {
    name: events.name,
    ref: events.ref,
  };

  if (!category) {
    return '';
  }

  //no of columns to show
  let maxMarketsToShow = columns && parseInt(columns);

  //if dropdown length is less than columns show only these many cols
  if (dropdown && Object.keys(dropdown).length < maxMarketsToShow) {
    maxMarketsToShow = Object.keys(dropdown).length;
  }

  return (
    (category && (
      <React.Fragment>
        <StyledEvents>
          <EventsTableHeader
            category={category}
            maxMarketsToShow={maxMarketsToShow}
            marketDropdown={dropdown}
            selectedmarketDropdown={selectedmarketDropdown}
            onChangeMarketDropdown={onChangeMarketDropdown}
            americanLayout={americanLayout}
            disableDropdown={disableDropdown}
            fightWinner={fightWinner}
          ></EventsTableHeader>
          {eventsToRender && (
            <EventsTableBody
              subcatsWithEvents={eventsToRender}
              selectionsToRender={selectionsToRender}
              maxMarketsToShow={maxMarketsToShow}
              marketTypes={selectedmarketDropdown}
              activeTabCategory={category}
              betslipSelections={betslipSelections}
              category={category}
              americanLayout={americanLayout}
              oddsFormat={oddsFormat}
              displayAmount={displayAmount}
              lazyloadlimit={lazyloadlimit}
              pageLayoutIsAmerican={pageLayoutIsAmerican}
              getActiveCategoryData={getActiveCategoryData}
              eventsCount={eventsCount}
              onVirtuals={onVirtuals}
            />
          )}
        </StyledEvents>
      </React.Fragment>
    )) ||
    ''
  );
};
EventsTableComponent.propTypes = {
  betslipSelections: PropTypes.array,
  events: PropTypes.shape({
    group: PropTypes.object,
    name: PropTypes.string,
    ref: PropTypes.string,
    selections: PropTypes.object,
  }),
  headers: PropTypes.array,
  marketDropdown: PropTypes.any,
  selectedmarketDropdown: PropTypes.any,
  marketsFetched: PropTypes.object,
  americanLayout: PropTypes.bool,
  oddsFormat: PropTypes.string,
  onChangeMarketDropdown: PropTypes.func,
  columns: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  displayAmount: PropTypes.number,
  lazyloadlimit: PropTypes.number,
  pageLayoutIsAmerican: PropTypes.bool,
  dropdown: PropTypes.object,
  getActiveCategoryData: PropTypes.func,
  eventsCount: PropTypes.object,
  disableDropdown: PropTypes.any,
  fightWinner: PropTypes.bool,
  onVirtuals: PropTypes.bool,
};

export const EventsTable = EventsTableComponent;
