import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

export const MarketWithinRow = ({ eventId }) => {
  useEffect(() => {
    PubSub.emit(PubsubEvents.CALL_MARKETS_APP, {
      eventId,
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section
      data-app="MarketsApp"
      data-eventid={eventId}
      data-market="v1x2"
    ></section>
  );
};
MarketWithinRow.propTypes = {
  eventId: PropTypes.number,
};
