/**
 * appconfig parameter cms name refreshTimeInSeconds
 */
import moment from 'moment';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import project from '../../../project';
export default class AppConfigHandlers {
  /**
   *
   * @param {*} refreshtime - appConfig
   * @param {*} callback - callback executed
   */
  static refreshTimeInSeconds = (refreshtime, callback) => {
    callback(refreshtime);
  };
  /**
   *
   * @param {Object} category - data, to prevent calculation if not require
   * @param {*} subcat - data, subcat data to make keys
   * @param {*} event - event data required in case of group by date
   * @param {*} groupBy - appconfig
   * @param {*} expandsubcatrefs  - appconfig
   * @param {*} collapseall  - appconfig
   * @returns
   */
  static groupByAndExpandCollapse(
    category,
    subcat,
    event,
    groupBy,
    expandsubcatrefs,
    collapseall
  ) {
    let key = null;
    let keyData = {};

    if (groupBy && groupBy === GLOBAL_CONSTANTS.DATE) {
      const timestamp = event.scheduledStart;
      const formattedDate = moment(timestamp).format(
        project?.timeFormats?.events?.primary || 'YYYY-MM-DD'
      );
      key = parseInt(
        moment(formattedDate).format(
          project.timeFormats?.events?.tertiary || 'x'
        )
      ); //date only timestamp

      keyData = {
        name: moment(parseInt(key)).format(
          project?.timeFormats?.events?.secondary || 'dddd Do MMMM'
        ),
        subcatName: subcat?.name,
        ref: subcat.ref,
        countryName: subcat?.countryName,
      };
    } else {
      //group by subcat
      key = subcat.ref;
      keyData = {
        name: subcat.name,
        ref: key,
        countryName: subcat?.countryName,
      };
    }
    if (!category['group'][key]) {
      //default each group data will have some meta info and events array, and also check if this needs to be collapsed
      const collapsed = this.isCollapsed(key, expandsubcatrefs, collapseall);
      return {
        key: key,
        groupingMetaData: { events: [], ...keyData, collapsed },
      };
    } else {
      return { key };
    }
  }

  static isCollapsed(key, expandsubcatrefs, collapseall) {
    if (!collapseall) {
      return false;
    } else {
      if (expandsubcatrefs && expandsubcatrefs.indexOf(key) !== -1) {
        return false;
      } else {
        return true;
      }
    }
  }

  /**
   *
   * @param {Object} groups , grouped event data by subcat or date
   * @param {Array} sortsubcatrefs , array of subcats to sort
   * @param {*} groupBy
   */
  static sortBySubcatRefs(data, subcatrefs) {
    return this.sortObjectByArray(data, subcatrefs);
  }
  /**
   *
   * @param {Object} groups , grouped event data by subcat or date
   * @param {Array} sortcatrefs , array of subcats to sort
   * @param {*} groupBy
   */
  static sortCategoryByRef(data, catrefs) {
    return this.sortObjectByArray(data, catrefs);
  }

  /**
   * important for when sorting by date
   * @param {Object} data, grouped data which needs to be sorted
   * @param {String} order, sortorder - appconfig
   */
  static sortGroupedData(data, order, groupBy) {
    let i = 0;
    //sort in ascending order by default
    return Object.keys(data)
      .sort((a, b) => {
        return order === 'DESC'
          ? parseInt(b) - parseInt(a)
          : parseInt(a) - parseInt(b);
      })
      .reduce((obj, key) => {
        obj[key] = data[key];

        if (groupBy === GLOBAL_CONSTANTS.DATE && i++ === 0) {
          //first date to be shown expanded no matter what is set in collapse all
          obj[key]['collapsed'] = false;
        }
        return obj;
      }, {});
  }

  static sortObjectByArray(data, arr) {
    if (typeof arr === GLOBAL_CONSTANTS.STRING) {
      arr = arr.split(',');
    }
    let sortedObject = {};
    arr &&
      arr.length > 0 &&
      arr.forEach(elem => {
        if (data[elem]) {
          sortedObject[elem] = data[elem];
          delete data[elem];
        }
      });
    //and for remaining keep the original order
    sortedObject = { ...sortedObject, ...data };

    return sortedObject;
  }
}
