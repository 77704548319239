import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import MarketSelect from 'UI/lines/MarketSelect';
import {
  StyledMarketName,
  SMN,
  StyledCatName,
  StyledRow,
} from 'UI/apps/EventsApp/EventsTableHeader';
import { EventMarketEmpty } from 'UI/apps/EventsApp/EventsTableBodySection';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { removeCharacter } from '../../../../core__events-utils';
/**
 * this events table header which contains dropdown of market and selected category title
 * @param {*} category active current selected category `category`
 * @param {*} maxMarketsToShow number of columns to show for markets `maxMarketsToShow`
 * @param {*} marketDropdown dropdown object for markets key/value with keys=marketrefs `marketDropdown`
 * @param {*} selectedmarketDropdown `current selected markets in dropdown `selectedmarketDropdown`
 * @param {*} onChangeMarketDropdown function call on change of market in dropdown`onChangeMarketDropdown`
 */

export const EventsTableHeader = ({
  category,
  maxMarketsToShow,
  marketDropdown,
  selectedmarketDropdown,
  onChangeMarketDropdown,
  americanLayout,
  showOSTableHeader,
  disableDropdown,
  fightWinner,
}) => {
  //if it's american layout no proceesing below this, just return category row as per design

  const headerData = __setHeaderData(marketDropdown, maxMarketsToShow);
  const [markets, setMarkets] = useState(headerData.markets);
  const [dropdown, setDropdown] = useState(headerData.dropdown);
  const [columns, setColumns] = useState(headerData.columns);
  const [showDropdown, setShowDropdown] = useState(headerData.showDropdown);
  const [selectedDropdown, setSelectedDropdown] = useState(
    selectedmarketDropdown
  );

  //for csr
  useEffect(() => {
    const { markets, dropdown, showDropdown, columns } = __setHeaderData(
      marketDropdown,
      maxMarketsToShow
    );
    setMarkets(markets);
    setDropdown(dropdown);
    setShowDropdown(showDropdown);
    setColumns(columns);

    selectedmarketDropdown &&
      setSelectedDropdown(selectedmarketDropdown.slice(0, columns));
  }, [marketDropdown, maxMarketsToShow, selectedmarketDropdown]);

  if (americanLayout) {
    return (
      category && (
        <StyledRow>
          <StyledCatName>{category.name}</StyledCatName>
        </StyledRow>
      )
    );
  }
  return (
    category && (
      <StyledRow>
        <StyledCatName>{category.name}</StyledCatName>
        {/* we're to show only markets based on columns from cms, and not on american layout */}
        {!americanLayout &&
          Array(columns)
            .fill()
            .map((v, i) => {
              //just to loop
              const defaultValue =
                (selectedDropdown && selectedDropdown[i]) || null;
              const defaultLabel =
                (marketDropdown && marketDropdown[defaultValue]) || null;
              return (
                <StyledMarketName key={Math.random()}>
                  {!disableDropdown && showDropdown ? (
                    <MarketSelect
                      instanceId={category.name + defaultLabel}
                      defaultValue={defaultValue}
                      defaultLabel={
                        (defaultLabel && defaultLabel.replace('{0}', '')) || ''
                      }
                      onChange={selected => {
                        onChangeMarketDropdown(i, selected.value, category.ref);
                      }}
                      options={markets.reduce((options, ref) => {
                        if (
                          selectedDropdown &&
                          selectedDropdown.indexOf(ref) === -1
                        ) {
                          const option = {
                            value: ref,
                            label:
                              dropdown[ref] && dropdown[ref].replace('{0}', ''),
                          };

                          options.push(option);
                        }
                        return options;
                      }, [])}
                    ></MarketSelect>
                  ) : (
                    <SMN>
                      {fightWinner
                        ? category.name === GLOBAL_CONSTANTS.BOXING &&
                          defaultLabel.includes(GLOBAL_CONSTANTS.MATCH_WINNER)
                          ? GLOBAL_CONSTANTS.FIGHT_WINNER
                          : (defaultLabel && defaultLabel.replace('{0}', '')) ||
                            ''
                        : !(category.name === GLOBAL_CONSTANTS.GOLF) &&
                          defaultLabel &&
                          removeCharacter(defaultLabel)}
                    </SMN>
                  )}
                </StyledMarketName>
              );
            })}
        {showOSTableHeader && <EventMarketEmpty></EventMarketEmpty>}
      </StyledRow>
    )
  );
};

EventsTableHeader.propTypes = {
  category: PropTypes.shape({
    name: PropTypes.string,
    ref: PropTypes.string,
  }),
  marketDropdown: PropTypes.object,
  maxMarketsToShow: PropTypes.number,
  onChangeMarketDropdown: PropTypes.func,
  selectedmarketDropdown: PropTypes.array,
  americanLayout: PropTypes.bool,
  showOSTableHeader: PropTypes.bool,
  disableDropdown: PropTypes.any,
  fightWinner: PropTypes.bool,
};

/**private functions */
function __setHeaderData(marketDropdown, maxMarketsToShow) {
  let result = {};
  try {
    if (marketDropdown && Object.keys(marketDropdown).length > 0) {
      const mkts = Object.keys(marketDropdown);
      result['markets'] = mkts;
      result['dropdown'] = marketDropdown;
      if (mkts.length <= maxMarketsToShow) {
        result['showDropdown'] = false;
        result['columns'] = parseInt(mkts.length);
      } else {
        result['showDropdown'] = true;
        result['columns'] = parseInt(maxMarketsToShow);
      }
    }
  } catch (error) {
    //error handle here
    result = {
      markets: [],
      dropdown: marketDropdown,
      showDropdown: false,
      columns: 0,
    };
  }
  return result;
}
