import styled, { css } from 'styled-components';
import { BRAND, GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

export const EventName = styled.div`
  font-size: 14px;
  color: ${({ theme }) => (theme.dark ? FONT.light : FONT.dark)};
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
  line-height: 120%;
  min-width: 0;
  align-self: stretch;
  flex: 3;
  @media (max-width: ${BREAKPOINT_S}) {
    flex: 2;
  }
  a {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
  }
`;

export const EventDate = styled.p`
  color: ${GREYS.silver};
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};

  font-size: 12px;
  &:first-child {
    flex: 1;
  }
`;

export const EventMarket = EventDate;

export const EventsRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1em 1em 0 1em;
  cursor: default;
  align-items: center;
  background-color: ${({ theme }) =>
    theme.dark ? GREYS.silverD : GREYS.white};
`;

export const MoreMarketsRow = styled(EventsRow)`
  padding-bottom: 1em;
  border-bottom: 1px solid ${BRAND.tertiary};
`;

export const StyledAnchor = styled.a`
  ${({ href }) =>
    !href &&
    css`
      pointer-events: none;
      cursor: pointer;
    `};
`;

export const RightArrow = styled.i`
  border: solid ${GREYS.silver};
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`;

export const EmptyAmericanMarket = styled.div`
  width: 56px;
`;

export const EventFixture = styled.span`
  min-width: 0;
`;
