import styled from 'styled-components';
import { GREYS, SHADOW } from 'UI/globals/colours';

export const EventsTable = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${GREYS.white};
  box-shadow: ${SHADOW};
  border-radius: 4px;
`;
