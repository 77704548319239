import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import theme from 'UI/globals/theme';
import { GREYS } from 'UI/globals/colours';
import { StyledSelect } from 'UI/lines/MarketSelectStyles';

const styles = {
  control: provided => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    ':hover': {
      border: 'none',
      boxShadow: 'none',
    },
    justifyContent: 'center',
    minHeight: 'auto',
    backgroundColor: 'none',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: `${GREYS.black}`,
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  valueContainer: provided => ({
    ...provided,
    justifyContent: 'center',
  }),
  option: provided => ({
    ...provided,
    textAlign: 'center',
  }),
};

const MarketSelect = ({ ...props }) => {
  const {
    options,
    defaultValue,
    defaultLabel,
    onChange,
    menuIsOpen,
    instanceId,
    extendedStyles = {},
    components = {},
    isOptionSelected,
    menuPlacement,
  } = props;
  return (
    <ThemeProvider theme={theme}>
      <StyledSelect
        isSearchable={false}
        styles={{ ...styles, ...extendedStyles }}
        options={options}
        defaultValue={
          defaultValue && defaultLabel
            ? { value: defaultValue, label: defaultLabel }
            : options[0]
        }
        instanceId={instanceId}
        onChange={onChange && onChange}
        menuIsOpen={menuIsOpen}
        components={components}
        isOptionSelected={isOptionSelected}
        menuPlacement={menuPlacement || 'bottom'}
      ></StyledSelect>
    </ThemeProvider>
  );
};

MarketSelect.propTypes = {
  options: PropTypes.array,
  defaultValue: PropTypes.string,
  defaultLabel: PropTypes.string,
  onChange: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  instanceId: PropTypes.any,
  extendedStyles: PropTypes.any,
  components: PropTypes.any,
  isOptionSelected: PropTypes.any,
  menuPlacement: PropTypes.string,
};

export default MarketSelect;
