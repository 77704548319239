import React from 'react';
import { EventDate } from './core__eventDate';
import { Market } from './core__market';
import { Teams } from './core__teams';
import { EventsTableRow } from '../core__eventsTableRow';

const MarketJennings = props => {
  return <Market {...props} showFractionalOdds={true} />;
};
export { EventDate, MarketJennings as Market, Teams, EventsTableRow };
