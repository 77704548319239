import styled from 'styled-components';
import { GREYS, FONT } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';

import {
  StyledMarketName,
  SMN,
  StyledCatName,
  StyledRow,
} from 'CORE__UI/apps/EventsApp/core__eventsTableHeader';

const SCN_EX = styled(StyledCatName)`
  color: ${GREYS.white};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;
const StyledRow_GDWN = styled(StyledRow)`
  border-bottom: 1px solid ${FONT.dark};
  background-color: ${GREYS.silverD};
  @media (max-width: ${BREAKPOINT_S}) {
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const SMN_GDWN = styled(SMN)`
  color: ${GREYS.white};
`;
export {
  StyledMarketName,
  SMN_GDWN as SMN,
  SCN_EX as StyledCatName,
  StyledRow_GDWN as StyledRow,
};
