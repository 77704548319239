import * as marketConf from './core__events-markets';
import { Constants } from './core__events-constants';
import { getCookie } from 'Services/cookie/core__cookies';
import { Translations } from './core__events-app';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';

import moment from 'moment';
import { getUrlEventData } from './core__events-fetch-data';
import project from '../../../project';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

/**
 * returns supported markets and categories based on appconfig, used for dropdown display
 * @param {Array} marketTypes , array of market types
 * @param {Object} appConfig , params of event app
 */
// const getAppConfigMarketsPerCategory = appConfig => {
//   const { extramarkets = false, defaultmarkets = false } = appConfig;
//   const marketConfig = {};
//   if (defaultmarkets) {
//     const defaultMarkets = defaultmarkets
//       .split('|')
//       .map(catMarkerts => {
//         const market = catMarkerts.split(':');
//         return {
//           category: market[0],
//           markets: market[1].split(',').map(x => { return { market: x } })
//         }
//       })
//     marketConfig.defaultMarkets = defaultMarkets;
//   }
//   if (extramarkets) {
//     const extraMarkets = extramarkets
//       .split('|')
//       .map(catMarkerts => {
//         const market = catMarkerts.split(':');
//         return {
//           category: market[0],
//           markets: market[1].split(',').map(x => { return { market: x } })
//         }
//       })
//     marketConfig.extraMarkets = extraMarkets;
//   }
//   return marketConfig;
// }

/**
 * returns category and subcategory from wildcard in url
 * @param {String} requestURL
 */
export const getCatSubcatFromUrl = (requestURL, urlAmendments) => {
  const pathParams = requestURL.split('/');
  let catreftoload = null,
    subcatreftoload = null,
    subcatidtoload = null;
  if (pathParams[2] && pathParams[2].trim() !== '') {
    catreftoload = pathParams[2];
  }
  if (pathParams[3] && pathParams[3].trim() !== '') {
    subcatreftoload = decodeURI(pathParams[3]);
    if (urlAmendments) {
      [subcatreftoload, subcatidtoload] = subcatreftoload.split(/-\s*([^-]+)$/);
    }
  }
  return {
    catreftoload: urlAmendments
      ? catreftoload?.toUpperCase().replace(/-/g, '_')
      : catreftoload,
    subcatreftoload: urlAmendments
      ? subcatreftoload?.toUpperCase().replace(/-/g, '_')
      : subcatreftoload,
    subcatidtoload,
  };
};

/**
 * to parse app config and get different parameters from it
 * @param {Object} appConfig app parameters
 */
export const parseAppConfig = appConfig => {
  //appconfig
  appConfig.columns = appConfig.columns
    ? parseInt(appConfig.columns)
    : Constants.DEFAULT_COLUMNS;

  appConfig.displayAmount =
    appConfig.displayAmount && parseInt(appConfig.displayAmount);

  //sub cat refs which will appear expanded when collapse all is set
  const collapseall = appConfig.collapseall;
  const expandsubcatrefs = appConfig.expandsubcatrefs?.split(',');

  //to sort rows by subcat
  const sortsubcatrefs =
    appConfig.sortsubcatrefs &&
    appConfig.sortsubcatrefs !== '' &&
    appConfig.sortsubcatrefs?.split(',');

  //markets from appconfig
  const marketTypes = (appConfig.market && appConfig.market.split(',')) || null;

  //groupby from appconfig
  const groupBy = appConfig.groupby;

  //category ref to load, required for sequencing
  const catreftoload =
    (appConfig.catreftoload && appConfig.catreftoload.split(',')) || null;

  //sort category tabs
  const sortcatref =
    (appConfig.sortcatref && appConfig.sortcatref.split(',')) || null;

  //is lazy loaded when ssr
  const lazyloadlimit = appConfig?.lazyloadlimit;

  //americanLayout layout categories
  const americanlayoutcatref = appConfig?.americanlayoutcatref;

  //categories are tabbed or list
  const categorytab = appConfig?.categorytab;

  const americanLayout =
    appConfig?.american === 'true' || appConfig?.american === true;

  //request url
  const requestURL = appConfig?.requestURL;

  //request url
  const fromSSR = appConfig?.fromSSR;

  const { catreftoload: catreftoloadFromUrl, subcatreftoload } = requestURL
    ? getCatSubcatFromUrl(requestURL, appConfig?.urlamendments)
    : {};
  const excludeCatRef = appConfig.excludecatref || '';

  //when disabled dropdown is true, enable dropdown for specific catrefs csv
  const catRefWithDropdown = appConfig.catrefwithdropdown || '';

  return {
    appConfig,
    collapseall,
    expandsubcatrefs,
    sortsubcatrefs,
    marketTypes,
    groupBy,
    catreftoload: catreftoload || catreftoloadFromUrl,
    lazyloadlimit,
    americanlayoutcatref,
    categorytab,
    americanLayout,
    requestURL,
    featured: appConfig?.featured,
    inrunning: appConfig?.inrunning,
    state: appConfig?.state,
    subcatreftoload: appConfig?.subcatreftoload || subcatreftoload,
    limit: appConfig?.limit,
    columns: appConfig.columns,
    sortorder: appConfig?.sortorder,
    sortcatref: sortcatref,
    fromSSR,
    disabledropdown: appConfig?.disabledropdown,
    subcatalbumref: appConfig?.subcatalbumref,
    marketalbumref: appConfig.marketalbumref,
    permissivealbum: appConfig?.permissivealbum,
    singleHandicapFromBE: appConfig?.singlehandicapfrombe,
    excludeCatRef: excludeCatRef,
    catRefWithDropdown,
  };
};

/**
 * to return remaining rows for each section
 * @param {Number} displayAmount
 * @param {Number} eventsPerSubcat
 */
export const getNumberOfRowsToShow = (displayAmount, eventsPerSubcat) => {
  if (eventsPerSubcat < displayAmount) {
    return eventsPerSubcat;
  } else if (eventsPerSubcat >= displayAmount) {
    return displayAmount;
  }
};

/**
 * checks if all rows to be displayed or not based on displayAmount
 * @param {Number} displayAmount
 * @param {Object} subcatsWithEvents
 */
export const viewAllRowsOrNot = (displayAmount, count) => {
  //hide view all button if total events are <= displayamount
  if (displayAmount) {
    if (count <= displayAmount) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

/**
 * This function is used to map market dropdown to categories when all data is fetched
 * @param {Object} state
 * @param {*} param1
 * @returns Object of each category containing array of market in dropdown
 */
export const getSelectedMarketDropdown = (
  state,
  { columns, american, disabledropdown }
) => {
  const appConfig = state.appConfig;
  if (!columns) {
    columns = Constants.DEFAULT_COLUMNS;
  }
  if (state.categoriesFetched) {
    const categories = state.categories
      ? Object.keys(state.categories)
      : state.categoriesFetched;
    const selectedMarkets = {};

    //cookies dropdown selection
    const selectedDropdownFromCookie =
      !disabledropdown &&
      state.cookies &&
      state.cookies['selectedDropdown'] &&
      JSON.parse(state.cookies['selectedDropdown']);

    //loop through each category
    categories.forEach(categoryRef => {
      //check if category is american layout then selected ones will be american markets
      const americanLayout = checkIfAmericanLayoutApplicable(
        american,
        categoryRef
      );

      //american
      if (americanLayout && marketConf[categoryRef]['american_market']) {
        selectedMarkets[categoryRef] = Object.keys(
          marketConf[categoryRef]['american_market']
        );
      } //cookies
      else if (
        selectedDropdownFromCookie &&
        selectedDropdownFromCookie[categoryRef]
      ) {
        selectedMarkets[categoryRef] = selectedDropdownFromCookie[categoryRef];
      }
      //others
      else {
        if (
          !marketConf[categoryRef] &&
          state[categoryRef] &&
          state[categoryRef]['markets_fetched']
        ) {
          selectedMarkets[categoryRef] = Object.keys(
            state[categoryRef]['markets_fetched']
          );
        } else {
          selectedMarkets[categoryRef] = getMarketsForCategory(
            categoryRef,
            americanLayout,
            columns,
            null,
            disabledropdown
          );
        }

        //check if fetched markets are not available in our configuration
        try {
          if (state[categoryRef] && state[categoryRef]['markets_fetched']) {
            const marketsFetched = Object.keys(
              state[categoryRef]['markets_fetched']
            );

            //selectedMarkets[categoryRef] as string to match combined markets as well
            const selMktAsString = ',' + selectedMarkets[categoryRef].join(',');
            if (marketsFetched.length > 0) {
              marketsFetched.reverse().forEach(fetchedMarketRef => {
                if (selMktAsString.indexOf(',' + fetchedMarketRef) === -1) {
                  selectedMarkets[categoryRef].splice(0, 0, fetchedMarketRef); //add them to start of array
                }
              });
            }
          }
        } catch (error) {
          //no handling
        }

        // Block added for gamesys
        if (
          appConfig &&
          appConfig.defaultmarket &&
          categoryRef === GLOBAL_CONSTANTS.SOCCER
        ) {
          const defMarketFromCms = appConfig.defaultmarket.split(':');
          const getMarkets = defMarketFromCms[1];
          if (getMarkets) {
            if (selectedMarkets[categoryRef].indexOf(getMarkets) > -1) {
              const getInd = selectedMarkets[categoryRef].indexOf(getMarkets);
              selectedMarkets[categoryRef].splice(getInd, 1);
              selectedMarkets[categoryRef].splice(1, 0, getMarkets);
            } else {
              selectedMarkets[categoryRef].splice(1, 0, getMarkets);
            }
          }
        }

        // sync with available columns
        if (disabledropdown && selectedMarkets[categoryRef].length > columns) {
          selectedMarkets[categoryRef] = selectedMarkets[categoryRef].slice(
            0,
            columns
          );
        }
      }
    });
    return selectedMarkets;
  }
};

/**
 * to get markets and categories for api url based on appconfig marketTypes catreftoload
 * @param {Array|String} marketTypes
 * @param {Array|String} catreftoload
 * @param {Boolean} americanLayout
 * @param {Number} columns
 * @param {Object} options
 * @param {Object} cookies
 * @returns Object
 */
export const getSupportedMarketsNew = (
  marketTypes,
  catreftoload,
  americanLayout,
  columns,
  options,
  cookies,
  disabledropdown
) => {
  //preprocess catref and market
  if (typeof catreftoload === 'string') catreftoload = catreftoload.split(',');
  if (typeof marketTypes === 'string') marketTypes = marketTypes.split(',');

  //case 0 if options are passed from within the code
  if (options && options.catreftoload) {
    marketTypes =
      typeof options.selectedDropdown === 'string'
        ? options.selectedDropdown.split(',')
        : options.selectedDropdown;
    catreftoload =
      typeof options.catreftoload === 'string'
        ? options.catreftoload.split(',')
        : options.catreftoload;
  }

  //case 4 if both market types and catref to load are passed, moved up to have test cases proper for else
  if (marketTypes && catreftoload) {
    let marketTypesToLoad = marketTypes;
    //if american layout add american markets
    if (americanLayout) {
      catreftoload.forEach(catRef => {
        if (marketConf[catRef] && marketConf[catRef].american_market) {
          marketTypesToLoad = marketTypesToLoad.concat(
            Object.keys(marketConf[catRef].american_market)
          );
        }
      });
    }

    return { markets: marketTypesToLoad, categories: catreftoload };
  }

  //case 3 catreftoload but not market types, moved up to have test cases proper for else
  if (!marketTypes && catreftoload) {
    let marketTypesToLoad = [];
    catreftoload.forEach(categoryRef => {
      const catMkt = getMarketsForCategory(
        categoryRef,
        americanLayout,
        columns,
        cookies,
        disabledropdown
      );
      if (catMkt) {
        marketTypesToLoad = marketTypesToLoad.concat(catMkt);
      }
    });
    return { markets: marketTypesToLoad, categories: catreftoload };
  }

  //case 2 market types but no cat ref to load,moved up to have test cases proper for else
  if (marketTypes && !catreftoload) {
    if (!americanLayout) {
      return { markets: marketTypes, categories: null };
    } else {
      //check if market type holds a category which is american layout
      let marketTypesToLoad = marketTypes;
      ['BASEBALL', 'AMERICAN_FOOTBALL', 'ICE_HOCKEY', 'BASKETBALL'].forEach(
        catRef => {
          if (
            marketConf[catRef]['markets'].some(
              mkt => marketTypes.indexOf(mkt) !== -1
            )
          ) {
            //load american markets for this category
            marketTypesToLoad = marketTypesToLoad.concat(
              Object.keys(marketConf[catRef]['american_market'])
            );
          }
        }
      );
      return { markets: marketTypesToLoad, categories: null };
    }
  }

  //case 1, no markettypes no catref to load
  if (!marketTypes && !catreftoload) {
    let marketTypesToLoad = marketConf.default.markets;

    //american layout default should also include american markets for below cats
    americanLayout &&
      ['BASEBALL', 'AMERICAN_FOOTBALL', 'ICE_HOCKEY', 'BASKETBALL'].forEach(
        catRef => {
          if (
            marketConf[catRef]['markets'].some(
              mkt => marketConf.default.markets.indexOf(mkt) !== -1
            )
          ) {
            //load american markets for this category
            marketTypesToLoad = marketTypesToLoad.concat(
              Object.keys(marketConf[catRef]['american_market'])
            );
          }
        }
      );

    return { markets: marketTypesToLoad, categories: null };
  }
};

/**
 * Get markets for a category from conf or cookies
 * @param {String} catRef
 * @param {Boolean} americanLayout
 * @param {Number} columns
 * @param {Object} cookies
 * @returns
 */
export const getMarketsForCategory = (
  catRef,
  americanLayout,
  columns,
  cookies,
  disabledropdown
) => {
  if (!columns) {
    columns = Constants.DEFAULT_COLUMNS;
  }
  //check if american layout then don't bother other logic
  if (
    americanLayout &&
    marketConf[catRef] &&
    marketConf[catRef].american_market
  ) {
    return Object.keys(marketConf[catRef].american_market);
  }
  if (!disabledropdown) {
    //check if it exist in cookie
    try {
      const selectedDropdown = cookies
        ? cookies['selectedDropdown']
        : getCookie('selectedDropdown');

      const mkt = JSON.parse(selectedDropdown)[catRef];
      if (mkt) return mkt;
    } catch (e) {
      //undefined
    }
  }

  //get first 2 markets from the conf
  try {
    if (!marketConf[catRef]) {
      return marketConf['default']['markets'].slice(0, columns);
    } else {
      return marketConf[catRef]['markets'].slice(0, columns);
    }
  } catch (e) {
    //undefined
  }
};

/**
 * getAdditional markets in case when selected are lesser than columns
 */
export const getAdditionalMarkets = (mkts, categoryRef, columns) => {
  marketConf[categoryRef]['markets'].forEach(x => {
    !mkts.includes(x) && mkts.length < columns && mkts.push(x);
  });
  return mkts;
};

//FIXME
export const dateOrdinal = d =>
  d +
  (31 === d || 21 === d || 1 === d
    ? Translations.get('number.st')
    : 22 === d || 2 === d
    ? Translations.get('number.nd')
    : 23 === d || 3 === d
    ? Translations.get('number.rd')
    : Translations.get('number.th'));

export const getDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('h');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);

  const time =
    `${hour}:${minutes} ` +
    (date.hour() >= 12
      ? Translations.get('time.pm')
      : Translations.get('time.am'));
  if (project?.dateFormatChange) {
    return `${month} ${day}, ${time}`;
  } else {
    return `${day} ${month}, ${time}`;
  }
};
export const getOSDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = date.date();
  const hour = date.format('h');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);

  const time =
    `${hour}:${minutes} ` +
    (date.hour() >= 12
      ? Translations.get('time.pm')
      : Translations.get('time.am'));
  if (project?.dateFormatChange) {
    return `${month} ${day}, ${time}`;
  } else {
    return `${day} ${month}, ${time}`;
  }
};
//For Getting date and time in american layout
export const getAmericanDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = dateOrdinal(date.date());
  const hour = date.format('HH');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);
  const time = `${hour}:${minutes} `;
  if (project?.dateFormatChange) {
    return `${month} ${day}, ${time}`;
  } else {
    return `${day} ${month}, ${time}`;
  }
};
export const getAmericanOSDate = ({ scheduledStart }) => {
  const date = moment(scheduledStart);
  const day = date.date();
  const hour = date.format('HH');
  const minutes = date.format('mm');
  const month = Translations.get(`month.${date.format('MMM')}`);

  const time = `${hour}:${minutes} `;
  return `${day} ${month}, ${time}`;
};
export const __getNameForAmericanButton = (selection, americanMarketType) => {
  let name = selection.name && selection.name.match(/[\d.()+-]+/);
  if (Array.isArray(name)) {
    name = name[0];
  }
  if (americanMarketType === Constants.SPREAD && name) {
    name = selection.name.split(' ').pop();
  }
  if (americanMarketType === Constants.MONEY_LINE && name) {
    name = '';
  }
  if (americanMarketType === Constants.TOTAL && name) {
    name =
      selection.typeRef === Constants.OVER
        ? `${Translations.get('market.O')} ` + name
        : `${Translations.get('market.U')} ` + name;
  }
  if (americanMarketType === Constants.SET_WINNER && name) {
    name = `${Translations.get('markets.set')} ` + name;
  }
  return name;
};

/**
 *
 * @param {Boolean} americanLayout from app config
 * @param {String} catref
 */
export const checkIfAmericanLayoutApplicable = (americanLayout, catref) => {
  if (
    americanLayout &&
    marketConf[catref] &&
    marketConf[catref]['american_market']
  ) {
    return true;
  } else {
    return false;
  }
};

export const shouldInvokeOutrights = (appConfig, catRef) => {
  const { caterefforout, subcaterefforout, group } = appConfig;
  if (caterefforout && caterefforout.indexOf(catRef) !== -1) {
    const url = getUrlEventData({
      ...appConfig,
      market: 'OUT',
      catreftoload: catRef,
      subcatreftoload: subcaterefforout,
    });
    PubSub.emit(PubsubEvents.SHOW_OUTRIGHTS, {
      url,
      group,
    });
    return true;
  } else {
    PubSub.emit(PubsubEvents.SHOW_OUTRIGHTS, {
      hide: true,
      group,
    });
    return false;
  }
};

//only for ordinals upto 20
export const getOrdinals = number => {
  switch (number) {
    case 1:
      return number + Translations.get('number.st');
    case 2:
      return number + Translations.get('number.nd');
    case 3:
      return number + Translations.get('number.rd');
    default:
      return number + Translations.get('number.th');
  }
};

// to remove numeric with special character from title
export const removeCharacter = str => {
  if (/[0-9]/.test(str)) {
    str = str.replace(/[0-9]/g, '');
    str = str.replace(/[-+.{}]/g, '');
  }
  return str.trim();
};
