import { StyledSelect } from 'CORE__UI/lines/dropdowns/MarketSelect/core__marketSelectStyles';
import styled from 'styled-components';
import { GREYS } from '../globals/colours';
const StyledSelect_GDWN = styled(StyledSelect)`
  div[class*='singleValue'] {
    color: ${GREYS.white};
    &:after {
      border-color: ${GREYS.white};
    }
  }
  div[class*='menu'] {
    color: ${GREYS.white};
    background-color: ${GREYS.silverD};
  }
  div[class*='option'] {
    color: ${GREYS.white};
    background-color: transparent;
  }
  div[class*='option']:hover {
    color: ${GREYS.white};
    background-color: ${GREYS.black};
    font-weight: 700;
  }
`;
export { StyledSelect_GDWN as StyledSelect };
