/**
 * Following actions to be performed in events app
 * 1. Set initial data map to be accessed in all list components
 * 2. Change events tab filter
 * 3. Change category tab filter
 *
 */

import {
  checkIfAmericanLayoutApplicable,
  getCatSubcatFromUrl,
  parseAppConfig,
} from './core__events-utils';
import AppConfigHandlers from './core__appConfigHandlers';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';
import { sortObjectArrayByKey } from 'Services/globalfunctions/core__global-functions';
/**
 * the objective of this action is to
 * 1 convert category array to map for easier filtering
 * 2 create a map of top level tabs to filter events accordingly
 * and save in redux store in that format,
 * @param {*} data, combined api response and appconfig for the events app
 */
export const initialStateFromRawData = data => {
  if (!data.category || data.category.length === 0) {
    return handleDataNotFound(204, appConfig);
  }
  //const fromSSR, to identify if its from ssr
  const fromSSR = data.fromSSR;

  //a flag to identify if category headers, active category needs to be updated or only data
  const updateMetaData = data.updateMetaData;

  //a map of categories used to render category tab, and events
  const categories = {};

  //appconfig, get all required parameters
  const {
    appConfig,
    collapseall,
    expandsubcatrefs,
    sortsubcatrefs,
    groupBy,
    americanLayout,
    categorytab,
    sortorder,
    sortcatref,
    lazyloadlimit,
  } = parseAppConfig(data.appConfig);

  if (data.category) {
    const looper = JSON.parse(JSON.stringify(data.category));

    looper.forEach(cat => {
      const category = { ...cat };
      let numberOfRowsToLoad = null;
      if (fromSSR && lazyloadlimit && lazyloadlimit !== '') {
        numberOfRowsToLoad = lazyloadlimit;
      }
      //initialize extra parameters to be used for render
      category['markets_fetched'] = {};
      category['selections'] = {}; //to display selections per each market

      //for grouping event data sub category or date wise
      category['group'] = {};

      //true or false to handle per different category
      category['americanLayout'] = checkIfAmericanLayoutApplicable(
        americanLayout,
        category.ref
      );

      category.subcat &&
        category.subcat.forEach(subcat => {
          //sort subcat event by timestamp
          if (subcat.event && groupBy !== GLOBAL_CONSTANTS.DATE) {
            subcat.event = sortObjectArrayByKey(subcat.event, 'scheduledStart');
          }

          subcat.event &&
            subcat.event.forEach(event => {
              if (numberOfRowsToLoad !== null && numberOfRowsToLoad <= 0) {
                return;
              }
              //to lazyload from server side limited rows in data
              if (numberOfRowsToLoad !== null) {
                numberOfRowsToLoad--;
              }
              //setting markets as a map in each event object for render
              event?.market?.forEach(market => {
                //initialize map
                if (!event.toDisplayMarket) {
                  event.toDisplayMarket = {};
                }
                if (!event.toDisplayMarket[market.typeRef]) {
                  event.toDisplayMarket[market.typeRef] = [];
                }

                //for headers and to display in table selections buttons
                if (!category['selections'][market.typeRef]) {
                  category['selections'][market.typeRef] = {};

                  market.selection &&
                    market.selection.forEach(selection => {
                      //selection headers for each category for a market
                      if (
                        !category['selections'][market.typeRef][
                          selection.typeRef
                        ] &&
                        selection.price &&
                        selection.displayed
                      ) {
                        category['selections'][market.typeRef][
                          selection.typeRef
                        ] = selection.name;
                      } else if (
                        !category['selections'][market.typeRef][
                          selection.typeRef
                        ]
                      ) {
                        category['selections'][market.typeRef][
                          selection.typeRef
                        ] = null;
                      }
                    });
                }
                //need to display only one market, but calculate standard deviation row that's why array assignment
                event.toDisplayMarket[market.typeRef].push(
                  removeUnusedEventsMarketData(market)
                );

                //markets meta data for category
                category['markets_fetched'][market.typeRef] =
                  market.typeRef === 'SSWIN' ? market.typeName : market.name;
              });

              //create an events map with grouping. to handle collapse, date grouping etc.
              const {
                key,
                groupingMetaData,
              } = AppConfigHandlers.groupByAndExpandCollapse(
                category,
                subcat,
                event,
                groupBy,
                expandsubcatrefs,
                collapseall
              );

              if (!category['group'][key]) {
                category['group'][key] = groupingMetaData;
              }

              //remove extra api data from event
              event = removeUnusedEventsData(event, appConfig);

              category['group'][key].subcatId = subcat.id;
              category['group'][key].subcatName = subcat.name;

              category['group'][key]['events'].push(event); //for data rendering this map wil be used
            });
        });

      delete category['subcat']; // not required since we already grouped above data

      //if there's some data in groups
      if (category['group'] && Object.keys(category['group']).length > 0) {
        if (groupBy === GLOBAL_CONSTANTS.DATE) {
          //when grouping is by date
          const ordered = AppConfigHandlers.sortGroupedData(
            category['group'],
            sortorder,
            groupBy
          );

          //order each event by date
          ordered &&
            Object.keys(ordered).forEach(key => {
              ordered[key].events = sortObjectArrayByKey(
                ordered[key].events,
                'scheduledStart'
              );
            });
          category['group'] = ordered;
        } else if (sortsubcatrefs) {
          //if grouping is by subcategories, default
          const ordered = AppConfigHandlers.sortBySubcatRefs(
            category['group'],
            sortsubcatrefs
          );
          category['group'] = ordered;
        }
      }

      //assign it to final object
      categories[category.ref] = { ...category };
    });
  }

  //get data from app config and required
  if (appConfig.requestURL && !appConfig.catreftoload) {
    const catSubcat = getCatSubcatFromUrl(
      appConfig.requestURL,
      appConfig?.urlamendments
    );
    appConfig.catreftoload = catSubcat.catreftoload;
  }

  let state = { appConfig };

  //if there's some data returned by api
  if (Object.keys(categories).length > 0) {
    //first category based on catref to load if passed otherwise first one fetched

    if (updateMetaData) {
      if (categorytab) {
        //set active tab category only if tabbed is set
        const activeTabCategory = getActiveTabCategory(categories, sortcatref);

        state['activeTabCategory'] = {
          id: activeTabCategory.id,
          ref: activeTabCategory.ref,
          name: activeTabCategory.name,
        };
      }
      state['pageLayoutIsAmerican'] = americanLayout;
    }

    //refactor state into fetched category wise data
    state = {
      ...state,
      ...setCategoryWiseDataInState(
        appConfig,
        categories,
        state['activeTabCategory'],
        fromSSR
      ),
    };
  }
  return { type: 'STORE_DATA', data: state };
};

export const activeCategoryFilter = category => {
  return { type: 'CHANGE_ACTIVE_CATEGORY', data: category };
};

export const updateBetslipsSelection = data => {
  const selectionId = data;
  if (selectionId) {
    return {
      type: 'CHANGE_ACTIVE_BETSLIP_SELECTION',
      data: selectionId,
    };
  } else {
    return { type: 'DO_NOTHING' };
  }
};

export const updateEventCount = data => {
  if (data.response) {
    data = { ...data.response, appConfig: data.appConfig };
  }

  if (data.category) {
    const counts = {};
    const { sortcatref, catreftoload, excludeCatRef } = parseAppConfig(
      data.appConfig
    );
    data.category.forEach(category => {
      if (
        (!catreftoload ||
          (catreftoload && catreftoload.indexOf(category.ref) !== -1)) &&
        excludeCatRef.indexOf(category.ref) === -1
      ) {
        counts[category.ref] = {
          count: category.eventCount,
          name: category.name,
        };
      }
    });
    return {
      type: 'UPDATE_CATEGORY_COUNT',
      data: sortcatref
        ? AppConfigHandlers.sortCategoryByRef(counts, sortcatref)
        : counts,
    };
  } else {
    return { type: 'DO_NOTHING' };
  }
};

/**functions to reduce data size which is not to be displayed */
function removeUnusedEventsData(event, appConfig) {
  const remove = [
    'displayed',
    'featured',
    'version',
    'virtual',
    'code',
    'market',
  ];

  if (!appConfig.showmarketcount) {
    remove.push('marketCnt');
  }
  remove.forEach(key => {
    delete event[key];
  });
  return event;
}
/**function to remove unused data from markets key  */
function removeUnusedEventsMarketData(market) {
  const remove = [
    'betBoostAvailable',
    'book',
    'cashOutAvailable',
    'displayed',
    'featured',
    'inRunning',
    'isFeatured',
    'sequence',
  ];
  remove.forEach(key => {
    delete market[key];
  });
  return market;
}

/** update category wise data in state */
function setCategoryWiseDataInState(
  appConfig,
  categories,
  activeTabCategory,
  fromSSR
) {
  let data = {};
  Object.keys(categories).forEach(categoryRef => {
    if (categories[categoryRef]) {
      //if data is from ssr, and not it activeTabCategory, remove events from it
      if (
        activeTabCategory &&
        activeTabCategory.id !== categories[categoryRef].id &&
        fromSSR
      ) {
        categories[categoryRef]['group'] = null; //emptying the event data not to send to client from ssr
      }

      data[categoryRef] = {};
      //american layouts
      data[categoryRef]['americanLayout'] =
        categories[categoryRef].americanLayout;

      //all events data
      data[categoryRef]['events'] = categories[categoryRef];

      //markets fetched meta data
      data[categoryRef][
        'markets_fetched'
      ] = AppConfigHandlers.sortObjectByArray(
        categories[categoryRef]['markets_fetched'],
        appConfig.markets
      );

      //selections fetchced meta data
      data[categoryRef]['selections'] = AppConfigHandlers.sortObjectByArray(
        categories[categoryRef]['selections'],
        appConfig.markets
      );
    }
  });
  if (appConfig.sortcatref) {
    data = AppConfigHandlers.sortCategoryByRef(data, appConfig.sortcatref);
  }
  return { ...data, categoriesFetched: Object.keys(data) };
}

export const changeMarketColumnSelection = data => {
  return { type: 'CHANGE_MARKET_COLUMN_SELECTION', data };
};

/**
 * returns active tab category based on sorting, ssr important
 * @param {Object} categories
 * @param {Array} sortcatref from appconfig
 * @returns
 */
const getActiveTabCategory = (categories, sortcatref) => {
  let activeTabCategory;
  if (sortcatref) {
    //loop through all for sort, as first one might not be in data
    sortcatref.forEach(catRef => {
      if (!activeTabCategory && categories[catRef]) {
        activeTabCategory = categories[catRef];
      }
    });
  } else {
    //return just first fetched category
    activeTabCategory = Object.values(categories)[0];
  }
  return activeTabCategory;
};
export const handleDataNotFound = (statusCode, appConfig) => {
  return {
    type: 'DATA_NOT_FOUND',
    statusCode,
    appConfig,
  };
};
export const translatedCatName = catNameTranslated => {
  return {
    type: 'TRANSLATED_CAT_NAME',
    catNameTranslated,
  };
};
