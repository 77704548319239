import React from 'react';
import styled from 'styled-components';
import { FONT, GREYS } from '../../globals/colours';
import {
  EventName,
  EventDate,
  EventMarket,
  EventsRow,
  MoreMarketsRow,
  RightArrow,
  StyledAnchor,
  EmptyAmericanMarket,
} from 'CORE__UI/apps/EventsApp/core__eventsTableRow';

import { BRAND } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';

const EN_GDWN = styled(EventName)`
  color: ${GREYS.white};
  background-color: ${GREYS.silverD};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 13px;
  }
`;
const ER_GDWN = styled(EventsRow)`
  background-color: ${GREYS.silverD};
  padding-left: 8px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 8px;
    padding-left: 10px;
    padding-right: 8px;
  }
`;

const StyledAnchorArrow = styled(StyledAnchor)`
  color: ${BRAND.favourite};
  @media (max-width: ${BREAKPOINT_S}) {
    font-size: 11px;
  }
`;

const MoreMarketsRow_GDWN = styled(MoreMarketsRow)`
  border-bottom: 1px solid ${FONT.dark};
  background-color: ${GREYS.silverD};
  padding-top: 0;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
  }
`;
const StyledAnchor_GDWN = styled(StyledAnchor)`
  color: ${GREYS.silver};
  background-color: ${GREYS.silverD};
`;

const RightArrow_GDWN = styled(RightArrow)`
  border-color: none;
  color: ${GREYS.silver};
  background-color: transparent;
  padding: 4px;
  margin-right: 8px;
  margin-left: 4px;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-right: 4px;
  }
`;

export {
  EN_GDWN as EventName,
  EventDate,
  EventMarket,
  ER_GDWN as EventsRow,
  EmptyAmericanMarket,
  StyledAnchorArrow,
  MoreMarketsRow_GDWN as MoreMarketsRow,
  RightArrow_GDWN as RightArrow,
  StyledAnchor_GDWN as StyledAnchor,
};

export const EventFixture = React.Fragment;
