import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CategoryTab } from 'UI/navigation/events-category-tab';
import { getIcon } from 'Services/icons/core__icons';
import { SC } from 'UI/apps/EventsApp/CategoryTabs';

import { carouselSettings as carouselSettings_LOCAL } from './carouselConfig';
import { carouselSettings as carouselSettings_CORE } from 'CORE__UI/apps/EventsApp/core__events-carousel-config';

import { ScrollCarousel } from 'Services/carousel/core__scroll-carousel';
import { detectMob } from 'Services/globalfunctions/core__global-functions';
import { getComponent } from 'Services/core__imports';

const carouselSettings = getComponent(
  carouselSettings_LOCAL,
  carouselSettings_CORE
);
/**
 * This component is for diplaying categories for events  app in tabs
 * @param categories Object of categories with keys as category refs `categories`
 * @param activeTabCategory Object of active category refs and name `activeTabCategory`
 * @param getActiveCategoryData function to get data for clicked category and activate it `getActiveCategoryData`
 */
export const CategoryTabsComponent = ({
  activeTabCategory,
  getActiveCategoryData,
  categories,
  catRefForOut,
}) => {
  const [categoriesForHeader, setCategoriesForHeader] = useState(categories);
  const [carouselArrowState, setCarouselArrowState] = useState(false);

  useEffect(() => {
    const isMobile = detectMob();
    if (isMobile && isMobile !== carouselArrowState) {
      setCarouselArrowState(isMobile);
    }
  }, [carouselArrowState]);

  useEffect(() => {
    setCategoriesForHeader(categories);
  }, [categories]);
  const settings = carouselSettings();

  return (
    <SC
      onMouseOver={() => setCarouselArrowState(true)}
      onMouseLeave={() => setCarouselArrowState(false)}
    >
      <ScrollCarousel
        categoryTab={true}
        arrows={carouselArrowState}
        movePercent={100}
        {...settings}
      >
        {categoriesForHeader &&
          Object.entries(categories).map(([categoryRef, categoryObj]) => {
            // if event count is null/no_count return false
            if (!catRefForOut && categoryObj.count < 1) {
              return false;
            }
            //when category is 0 count but outrights to be loaded
            if (
              categoryObj.count < 1 &&
              catRefForOut &&
              catRefForOut.indexOf(categoryRef) === -1
            ) {
              return false;
            }
            //key is object keys and index is iterator index
            const icon = getIcon(categoryRef);
            return (
              <React.Fragment key={categoryRef}>
                <CategoryTab
                  active={categoryRef === activeTabCategory?.ref}
                  k={categoryRef}
                  onClick={() => {
                    getActiveCategoryData(categoryRef);
                  }}
                  label={categoryObj.name}
                  icon={icon}
                  count={categoryObj.count}
                />
              </React.Fragment>
            );
          })}
      </ScrollCarousel>
    </SC>
  );
};

CategoryTabsComponent.propTypes = {
  activeTabCategory: PropTypes.shape({
    ref: PropTypes.any,
  }),
  carouselArrowState: PropTypes.any,
  categories: PropTypes.object,
  getActiveCategoryData: PropTypes.func,
  setActiveCatgory: PropTypes.func,
  appid: PropTypes.number,
  catRefForOut: PropTypes.string,
};

export const CategoryTabs = CategoryTabsComponent;
