import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { TeamName, Team, TeamScore } from 'UI/apps/EventsApp/Teams';
import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { Constants } from '../../../../../core__events-constants';

/**
 * @param {string} layout name of layout
 * @param {string} eventName name of event
 * @param {string} score score of event
 */

export const Teams = ({ category, event, americanLayout }) => {
  const teamReversed =
    category.ref !== Constants.AMERICAN_FOOTBALL_REF &&
    event.name.indexOf(' @ ') !== -1;

  const [server, setServer] = useState(null);
  const [teamScore, setTeamScore] = useState({});
  const [teams, setTeams] = useState(__getTeamNames(event));
  const [eventSocketListener, setEventSocketListener] = useState(null);

  useEffect(() => {
    setTeams(__getTeamNames(event));
    subscribeEventSocket();
    listenEventSocket();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      eventSocketListener && eventSocketListener.unsubscribe();
    };
  }, [eventSocketListener]);

  const subscribeEventSocket = () => {
    const subscriptionObject = {
      categoryRef: category.ref,
      providerRef: event.providerRef,
      sourceKey: event.sourceKey,
    };
    PubSub.emit(PubsubEvents.SOCKET_EVENT_SUBSCRIBER, subscriptionObject);
  };

  const listenEventSocket = () => {
    const listener = PubSub.listen(PubsubEvents.SOCKET_EVENT_LISTEN, wsData => {
      //handle event update here
      if (wsData.sourceKey === event.sourceKey) {
        //set game score
        if (wsData.catRef === Constants.TENNIS_REF) {
          const tennisScore = wsData?.score?.split(':');
          setTeamScore({
            home: tennisScore[0],
            away: tennisScore[1],
          });
        } else if (wsData.catRef === Constants.DARTS_REF) {
          setTeamScore({
            home: wsData?.game?.home,
            away: wsData?.game?.away,
          });
        } else if (wsData.catRef === Constants.CRICKET_REF) {
          if (wsData.game?.score?.length) {
            const parsed = {
              home: '',
              away: '',
            };

            wsData.game.score.forEach(inning => {
              if (inning?.home) {
                parsed['home'] += inning.home + ' ' || ' ';
              }
              if (inning?.away) {
                parsed['away'] += inning.away + ' ' || ' ';
              }
            });

            setTeamScore({
              home: parsed.home.trim(),
              away: parsed.away.trim(),
            });
          } else {
            setTeamScore({
              home: wsData?.game?.home,
              away: wsData?.game?.away,
            });
          }
        } else {
          if (
            wsData?.game &&
            wsData?.game?.home !== teamScore.home &&
            wsData?.game?.away !== teamScore.away
          ) {
            setTeamScore({
              home: wsData?.game?.home,
              away: wsData?.game?.away,
            });
          }
        }
        //set possesion or server
        if (wsData.server) {
          setServer(wsData.server);
        }
      }
    });
    setEventSocketListener(listener);
  };

  return (
    <React.Fragment>
      <Team americanLayout={americanLayout}>
        <TeamName isServing={server === '1'} americanLayout={americanLayout}>
          {Array.isArray(teams) ? teams[0] : teams}
        </TeamName>
        <TeamScore>
          {teamReversed ? teamScore['away'] : teamScore['home']}
        </TeamScore>
      </Team>
      <Team americanLayout={americanLayout}>
        <TeamName isServing={server === '2'} americanLayout={americanLayout}>
          {Array.isArray(teams) && teams[1]}
        </TeamName>
        <TeamScore>
          {teamReversed ? teamScore['home'] : teamScore['away']}
        </TeamScore>
      </Team>
    </React.Fragment>
  );
};

Teams.propTypes = {
  event: PropTypes.object,
  teamScore: PropTypes.object,
  americanLayout: PropTypes.bool,
  category: PropTypes.object,
  server: PropTypes.string,
};

export const __getTeamNames = event => {
  // genrate Teams data
  const delimiters = [' v ', ' 対 ', ' @ '];
  let teamNames = event.name;
  delimiters.find(d => {
    if (teamNames.indexOf(d) >= 0) teamNames = event.name.split(d);
  });

  return teamNames;
};
