export const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'STORE_DATA': {
      const newState = { ...state };
      Object.keys(state).forEach(key => {
        if (action.data[key]) {
          if (Array.isArray(action.data[key])) {
            action.data[key] = [...state[key], ...action.data[key]];
          } else if (typeof action.data[key] === 'object') {
            newState[key] = { ...state[key], ...action.data[key] };
          } else {
            newState[key] = action.data[key];
          }
          delete action.data[key];
        }
      });
      return { ...newState, ...action.data };
    }
    case 'UPDATE_CATEGORY_COUNT':
      return { ...state, categories: action.data };
    case 'CHANGE_ACTIVE_CATEGORY':
      return { ...state, activeTabCategory: action.data };

    case 'CHANGE_ACTIVE_HEADER':
      return { ...state, activeTabHeader: action.data };

    case 'CHANGE_ACTIVE_BETSLIP_SELECTION':
      return { ...state, betslipSelections: action.data };

    case 'CHANGE_MARKET_COLUMN_SELECTION': {
      state[action.data.categoryRef]['selectedmarketDropdown'] =
        action.data.selectedDropdown;

      return { ...state };
    }
    case 'STORE_SUBCAT_REF':
      return { ...state, subcatRef: action.subcatRef };
    case 'DATA_NOT_FOUND':
      return {
        ...state,
        statusCode: action.statusCode,
        appConfig: action.appConfig,
      };
    case 'TRANSLATED_CAT_NAME':
      return {
        ...state,
        catNameTranslated: action.catNameTranslated,
      };
    default:
      return state;
  }
};
