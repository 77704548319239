import React from 'react';
import PubSub from 'Services/pubsub/core__pubsub';
import { EventsAppContainer } from './components';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
import { Constants } from '../core__events-constants';
import { EventsAppTitle } from 'UI/navigation/TopLink';
import { connect } from 'react-redux';
import {
  checkIfAmericanLayoutApplicable,
  getCatSubcatFromUrl,
  getMarketsForCategory,
} from '../core__events-utils';
import { getCookie, createCookie } from 'Services/cookie/core__cookies';
import PropTypes from 'prop-types';
import { BREAKPOINT_M } from 'UI/globals/breakpoints';
import AppConfigHandlers from '../core__appConfigHandlers';
import Spinner from 'UI/spinner/Spinner';
import { FSBTheme } from 'Services/core__fsb-theme';
import { fetchEventsCount } from '../core__events-fetch-data';
import { getRequest } from 'Services/http/core__axios';
import { Translations } from '../core__events-app';

class EventsApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: props?.appConfig?.active || !props.appConfig?.eventsHeaderActive,
      eventsHeaderActive: props?.appConfig?.eventsHeaderActive,
      title: props?.appConfig?.title,
      categorytab: props?.appConfig?.categorytab,
      oddsFormat: props?.oddsFormat,
      selectedMarkets: props?.selectedMarkets,
      columns: props?.appConfig?.columns || Constants.DEFAULT_COLUMNS,
      noData:
        props?.categoriesFetched && props?.categoriesFetched?.length === 0,
      pageLayoutIsAmerican:
        props?.appConfig?.american === 'true' ||
        props?.appConfig?.american === true,
      theme: props.appConfig?.theme,
      disableDropdown: props.appConfig.disabledropdown,
      catRefWithDropdown: props.appConfig.catrefwithdropdown || '',
      hideTitle: props.appConfig.hidetitle,
      showEventTitle: false,
    };

    if (this.state.eventsHeaderActive) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: this.props?.appConfig?.appid,
        title: this.props?.appConfig?.title,
      });
    }
  }

  /**
   * to hit api for events app overall
   */
  getData = () => {
    const shouldCallApi = this.shouldApiCall();

    //fetch data if not fetched by server side
    if (shouldCallApi) {
      this.setState({ showSpinner: true });
      this.props
        .fetchData(this.props.url, null, {
          ...this.props.appConfig,
          fromSSR: false,
          subcategoryRef: this.props.subcategoryRef,
        })
        .then(() => {
          this.setState({ showSpinner: false });
          this.emitterForCategoryListApp();
        });
    }
  };
  shouldApiCall = () => {
    //do not call api if not active
    if (!this.state.active) {
      return false;
    } else if (
      !this.props.categoriesFetched ||
      this.props.categoriesFetched.length === 0
    ) {
      return true;
    } else if (this.props.categoriesFetched) {
      let apiCall = true;
      this.props.categoriesFetched.map(catRef => {
        if (this.props.state[catRef]) {
          apiCall = false; //data already exist do not call api
        }
      });
      return apiCall;
    }
  };
  componentDidMount() {
    if (this.props.notFound) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: this.props?.appConfig?.appid,
        hide: true,
      });
      return false;
    }
    try {
      if (window.innerWidth <= parseInt(BREAKPOINT_M)) {
        this.setState({ columns: 1 });
      } else {
        this.setState({ columns: this.state.columns });
      }
    } catch (error) {
      //no handling required
    }

    const pathParams = this.props.appConfig.requestURL?.split('/');
    let catreftoload = null,
      subcatreftoload = null;
    if (pathParams[2] && pathParams[2].trim() !== '') {
      catreftoload = pathParams[2];
    }
    if (pathParams[3] && pathParams[3].trim() !== '') {
      subcatreftoload = decodeURI(pathParams[3]);
    }

    this.props.getSubcatRef(
      catreftoload?.toUpperCase().replace(/-/g, '_'),
      subcatreftoload?.slice(subcatreftoload?.lastIndexOf('-') + 1)
    );
    //get data in case if not fetched from ssr
    this.getData();

    const state = { category: this.props.categoriesFetched };
    if (this.props.appConfig) {
      state['title'] = this.props.appConfig.title;
      state['categorytab'] = this.props.appConfig.categorytab;
      state['active'] = !this.props.appConfig?.eventsHeaderActive;
      state['eventsHeaderActive'] = this.props.appConfig?.eventsHeaderActive;
    }

    // when header tabs are changed
    this.listenerForHeadersApp();

    //listen to betslip change
    this.listenerForBetslipApp();

    //listen to pubsub for odds change
    this.listenerForOddsFormat();

    //listen to window resize
    this.listenerForWindowResize();

    //emitter for category list app
    this.emitterForCategoryListApp();

    //to hide headers based on cound
    if (!this.state.active && this.state.eventsHeaderActive) {
      this.fetchCountAndHideHeader();
    }
    //if appconfig refresh time is set activate a timer function to hit loaded page api in that interval
    if (this.props?.appConfig?.refreshtime) {
      AppConfigHandlers.refreshTimeInSeconds(
        this.props.appConfig.refreshtime,
        this.refreshapp
      );
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.subcategoryRef !== prevProps.subcategoryRef &&
      this.props.appConfig.urlamendments
    ) {
      this.getData();
    }
  }

  fetchCountAndHideHeader = async () => {
    //only to hide inactive apps, active apps already handled, to hide second and third etc. when no data present
    const { subcatreftoload: subcat } = getCatSubcatFromUrl(
      this.props?.appConfig?.requestURL,
      this.props?.appConfig?.urlamendments
    );

    const eventsCountData =
      this.props?.appConfig &&
      (await fetchEventsCount(this.props.appConfig, getRequest, subcat));
    if (!eventsCountData?.category || eventsCountData?.category?.length === 0) {
      PubSub.emit(PubsubEvents.EventHeaderUpdate, {
        appid: this.props?.appConfig?.appid,
        hide: true,
      });
    }
  };

  listenerForHeadersApp = () => {
    if (this.state.eventsHeaderActive) {
      this.eventsAppActive = PubSub.listen(
        PubsubEvents.EventsAppActive,
        wsData => {
          if (wsData.appid === parseInt(this.props?.appConfig?.appid)) {
            this.setState({ active: true, noData: false }, () => {
              this.getData(); //for case when events app initially had no data
            });
          } else if (wsData.appid === 0) {
            this.setState({ active: true, noData: false }, () => {
              this.getData(); //for case when events app initially had no data
            });
          } else {
            this.setState({ active: false });
          }
        }
      );
    }
  };
  listenerForBetslipApp = () => {
    this.eventsAppBetslipUpdate = PubSub.listen(
      PubsubEvents.EventsAppBetslipUpdate,
      wsData => {
        this.props.commonFunction({
          type: Constants.UpdateBetslipsSelection,
          data: wsData,
        });
      }
    );
  };

  listenerForOddsFormat = () => {
    this.oddsValue = PubSub.listen(PubsubEvents.oddsValue, wsData => {
      if (wsData === Constants.DECIMAL) {
        this.setState({ oddsFormat: Constants.DECIMAL });
      } else if (wsData === Constants.AMERICAN) {
        this.setState({ oddsFormat: Constants.AMERICAN });
      } else {
        this.setState({ oddsFormat: Constants.FRACTIONAL });
      }
    });
  };

  listenerForWindowResize = () => {
    this.breakPoint = PubSub.listen(PubsubEvents.BreakPoint, wsData => {
      if (wsData === 'BREAKPOINT_M' || wsData === 'BREAKPOINT_S') {
        this.setState({ columns: 1 });
      } else {
        this.setState({ columns: this.props?.appConfig?.columns });
      }
    });
  };

  emitterForCategoryListApp = () => {
    const { catreftoload, subcatreftoload } = getCatSubcatFromUrl(
      this.props?.appConfig?.requestURL,
      this.props?.appConfig?.urlamendments
    );
    if (catreftoload) {
      const state = this.props.state;
      const activeTabCategory = this.props.activeTabCategory;
      const groups =
        state[activeTabCategory?.ref || catreftoload]?.events?.group;
      const catNameTranslated = this.props.catNameTranslated;
      if (groups) {
        const subcats = Object.keys(groups);
        if (subcats[0] && groups[subcats[0]]) {
          const countryName = groups[subcats[0]]?.countryName;
          const subcatName =
            groups[subcats[0]]?.subcatName || groups[subcats[0]]?.name;
          const subcatRef = groups[subcats[0]]?.ref;
          const data = {
            category: activeTabCategory?.ref || catreftoload,
            subcat: this.props.subcategoryRef ?? (subcatreftoload && subcatRef),
            countryName: subcatreftoload && countryName,
            category_label: activeTabCategory?.name || catreftoload,
            subcat_label: subcatreftoload && subcatName,
            forceUpdate: subcatreftoload && true,
            showTitle: subcatreftoload && true,
            catNameTranslated: catNameTranslated,
          };
          PubSub.emit(PubsubEvents.UpdateCategoryListSelection, data);
        }
      } else {
        if (this.props.categoriesFetched)
          PubSub.emit(PubsubEvents.UpdateCategoryListSelection, {
            category: catreftoload,
            subcat: null,
            countryName: 0,
          });
      }
    }
  };

  refreshapp = timeInSeconds => {
    setTimeout(() => {
      //hit the api only if this events app is active otherwise
      //just keep running the clock and wait for it to get active
      if (this.state.active) {
        this.loadActiveCategoryData();
      }
      this.refreshapp(timeInSeconds);
    }, parseInt(timeInSeconds) * 1000);
  };

  loadActiveCategoryData = () => {
    const activeTabCategory = this.props.activeTabCategory;
    const selectedMarkets = this.state.selectedMarkets;

    //if tabbed only fetch selected category otherwise all
    let catreftoload = null;
    let selectedDropdown = null;
    if (this.state.categorytab && activeTabCategory) {
      catreftoload = activeTabCategory.ref;
      selectedDropdown =
        (selectedMarkets && selectedMarkets[activeTabCategory.ref]) || null;
    }

    //fetch data
    this.props.fetchData(
      null,
      null,
      {
        ...this.props.appConfig,
        catreftoload: catreftoload,
        market: selectedDropdown && selectedDropdown.join(','),
        fromSSR: false,
      },
      false
    );
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const selectedMarkets = nextProps.selectedMarkets;
    const prevSelectedMarkets = prevState.selectedMarkets;
    if (nextProps?.statusCode) {
      let hideEventsTab = true;
      if (nextProps?.appConfig?.categorytab) {
        hideEventsTab = false;
      }
      hideEventsTab &&
        PubSub.emit(PubsubEvents.EventHeaderUpdate, {
          appid: nextProps?.appConfig?.appid,
          hide: true,
        });
    }

    if (!prevSelectedMarkets) {
      return { selectedMarkets };
    } else {
      let retain = true;
      Object.keys(selectedMarkets).forEach(catRef => {
        if (!prevSelectedMarkets[catRef]) {
          retain = false;
        }
      });
      return retain ? {} : { selectedMarkets };
    }
  }

  /**
   * this function is for changing dropdown in header callback
   * @param {Number} index
   * @param {String} marketRef
   * @param {String} categoryRef
   */
  onChangeMarketDropdown = (index, marketRef, categoryRef) => {
    const selectedMarkets = this.state.selectedMarkets;
    //make update in store state
    const data = [...selectedMarkets[categoryRef]];

    // Find index of element to move to new position
    const foundIndex = data.findIndex(el => el === marketRef);

    // Remove element from array
    data.splice(foundIndex, 1);

    // Replace Element at new position in array
    data.splice(index, 0, marketRef);

    selectedMarkets[categoryRef] = data;

    //dispatch updated state to store
    this.props.changeMarketColumnSelection({
      selectedDropdown: data,
      categoryRef: categoryRef,
    });

    //hit api to get latest data for that market
    this.props.fetchData(
      null,
      null,
      {
        ...this.props.appConfig,
        catreftoload: categoryRef,
        market: data.join(','),
        fromSSR: false,
      },
      false
    );

    //update state to propagate in app
    this.setState({ selectedMarkets });

    //set in cookie, only the changes value
    const selectedDropdownInCookie =
      (getCookie('selectedDropdown') &&
        JSON.parse(getCookie('selectedDropdown'))) ||
      {};
    selectedDropdownInCookie[categoryRef] = data;
    createCookie('selectedDropdown', JSON.stringify(selectedDropdownInCookie));
  };

  componentWillUnmount() {
    //Unsubscribing from PubSubs
    this.eventsAppActive?.unsubscribe();
    this.eventsAppBetslipUpdate?.unsubscribe();
    this.oddsValue?.unsubscribe();
    this.breakPoint?.unsubscribe();
  }

  /**
   * to change active category from category tabs
   */
  getActiveCategoryData = catreftoload => {
    //read from cookie for user selected markets
    const selectedDropdown =
      (this.state.selectedMarkets &&
        this.state.selectedMarkets[catreftoload]) ||
      getMarketsForCategory(
        catreftoload,
        checkIfAmericanLayoutApplicable(
          this.props.appConfig.american,
          catreftoload
        ),
        this.state.columns
      );

    //set active category to load data quickly
    this.props.activeCategoryFilter({ ref: catreftoload });

    //fetch new data as well for latest update in background
    this.props.fetchData(
      null,
      null,
      {
        ...this.props.appConfig,
        catreftoload,
        market: selectedDropdown && selectedDropdown.join(','),
        fromSSR: false,
      },
      false
    );
  };

  showTitle = value => {
    this.setState({
      ...this.state,
      showEventTitle: value,
    });
  };
  render() {
    return (
      <FSBTheme theme={this.state.theme}>
        <React.Fragment>
          {!this.state.hideTitle &&
            !this.state.eventsHeaderActive &&
            this.state.showEventTitle && (
              <EventsAppTitle
                active={true}
                label={
                  this.props?.appConfig?.istranslated
                    ? Translations.get(
                        `text.${this.state?.title
                          .replace(/\s/g, '')
                          .toLowerCase()}`
                      )
                    : this.state?.title
                }
              />
            )}
          {this.state.showSpinner && this.state.active && (
            <Spinner display={true} width={25} />
          )}

          <EventsAppContainer
            active={this.state.active}
            state={this.props.state}
            categorytab={this.state.categorytab}
            oddsFormat={this.state.oddsFormat || Constants.FRACTIONAL}
            selectedMarkets={this.state.selectedMarkets}
            onChangeMarketDropdown={this.onChangeMarketDropdown}
            columns={this.state.columns}
            getActiveCategoryData={this.getActiveCategoryData}
            displayAmount={
              this.props?.appConfig?.displayamount &&
              parseInt(this.props.appConfig.displayamount)
            }
            lazyloadlimit={
              this.props?.appConfig?.lazyloadlimit &&
              parseInt(this.props?.appConfig?.lazyloadlimit)
            }
            pageLayoutIsAmerican={this.state.pageLayoutIsAmerican}
            categoriesFetched={this.props.categoriesFetched}
            disableDropdown={this.state.disableDropdown}
            catRefForOut={this.props?.appConfig?.caterefforout}
            fightWinner={this.props?.appConfig?.fightwinner}
            catRefWithDropdown={this.state.catRefWithDropdown}
            onVirtuals={this.props.appConfig.onvirtuals}
            showTitle={this.showTitle}
          />
        </React.Fragment>
      </FSBTheme>
    );
  }
}

EventsApp.propTypes = {
  appConfig: PropTypes.object,
  state: PropTypes.object,
  activeTabCategory: PropTypes.object,
  category: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fetchData: PropTypes.func,
  oddsFormat: PropTypes.string,
  url: PropTypes.any,
  commonFunction: PropTypes.func,
  selectedMarkets: PropTypes.any,
  changeMarketColumnSelection: PropTypes.func,
  activeCategoryFilter: PropTypes.func,
  statusCode: PropTypes.number,
  categoriesFetched: PropTypes.array,
  notFound: PropTypes.object,
  catNameTranslated: PropTypes.string,
  subcategoryRef: PropTypes.string,
  getSubcatRef: PropTypes.func,
};

export const mapStateToProps = state => ({
  subcategoryRef: state.data.subcatRef,
});

export default connect(mapStateToProps, null)(EventsApp);
