import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { ViewMoreBar, ViewMoreLabel } from 'UI/apps/EventsApp/EventsTableBody';
import {
  getNumberOfRowsToShow,
  viewAllRowsOrNot,
} from '../../../../core__events-utils';

import { EventsTableBodySection } from './EventsTableBodySection';
import { Translations } from '../../../../core__events-app';

/**
 * this component is a wrapper on multiple body section to preprocess grouped subcats data
 * @param {Object} subcatsWithEvents object of multiple subcategory contains events array and subcategory details `subcatsWithEvents`
 * @param {Object} marketTypes array of market types to be displayed in subheader `marketTypes`
 * @param {Object} maxMarketsToShow number of columns to show `maxMarketsToShow`
 * @param {Object} betslipSelections array of selection ids whose betslip is selected `betslipSelections`
 * @param {Object} category object of category `category`
 * @param {Object} selectionsToRender object of selection refs `selectionsToRender`
 */
export const EventsTableBody = ({
  subcatsWithEvents,
  selectionsToRender,
  maxMarketsToShow,
  marketTypes,
  activeTabCategory,
  betslipSelections,
  category,
  americanLayout,
  oddsFormat,
  displayAmount,
  lazyloadlimit,
  pageLayoutIsAmerican,
  getActiveCategoryData,
  onVirtuals,
}) => {
  const eventsCount =
    subcatsWithEvents &&
    Object.keys(subcatsWithEvents).reduce((acc, key) => {
      if (subcatsWithEvents[key] && subcatsWithEvents[key].events) {
        acc += subcatsWithEvents[key].events.length;
      }
      return acc;
    }, 0);
  const showViewAll = displayAmount ? displayAmount < eventsCount : false;

  const [viewAll, setViewAll] = useState(
    viewAllRowsOrNot(displayAmount, eventsCount)
  );
  const [viewLessButton, setViewLessButton] = useState(false);
  const [lazyload, setLazyload] = useState(!isNaN(lazyloadlimit) || false);

  useEffect(() => {
    if (displayAmount || lazyloadlimit) {
      !viewAll && setViewAll(viewAllRowsOrNot(displayAmount, eventsCount));
      viewAll && setLazyload(false);
    }
  }, [subcatsWithEvents]); // eslint-disable-line react-hooks/exhaustive-deps

  const isInitialMount = useRef(true);

  /**
   * returns a react inner component based on different subcat values
   * @param {*} subcat
   */
  const eventsTableBodySection = (subcat, numberOfRowsToShow) => {
    if (numberOfRowsToShow > 0) {
      return (
        <EventsTableBodySection
          subcat={subcat}
          marketTypes={marketTypes}
          selectionsToRender={selectionsToRender}
          maxMarketsToShow={maxMarketsToShow}
          activeTabCategory={activeTabCategory}
          betslipSelections={betslipSelections}
          category={category}
          americanLayout={americanLayout}
          oddsFormat={oddsFormat}
          numberOfRowsToShow={numberOfRowsToShow}
          pageLayoutIsAmerican={pageLayoutIsAmerican}
          onVirtuals={onVirtuals}
        />
      );
    }
  };

  const getBodySections = () => {
    let bodysections = [];
    if (subcatsWithEvents instanceof Object) {
      try {
        let numberOfRows = lazyload
          ? parseInt(lazyloadlimit)
          : parseInt(displayAmount);

        bodysections = Object.keys(subcatsWithEvents).reduce((acc, key) => {
          const subcat = subcatsWithEvents[key];

          if (subcat.events && subcat.events.length > 0) {
            if (numberOfRows === 0) {
              return acc;
            }
            const numberOfRowsToShow =
              (!viewAll || lazyload) && numberOfRows
                ? getNumberOfRowsToShow(numberOfRows, subcat.events.length)
                : subcat.events.length;

            acc.push(
              <React.Fragment key={key}>
                {eventsTableBodySection(subcat, numberOfRowsToShow)}
              </React.Fragment>
            );
            //substract to get next round number of rows
            if (!viewAll || lazyload) {
              numberOfRows = numberOfRows - numberOfRowsToShow;
            }
          }
          return acc;
        }, []);
      } catch (error) {
        //handle error here if required
      }
    }
    return bodysections;
  };

  const [bodySections, setBodySections] = useState(getBodySections());

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      // Your useEffect code here to be run on update
      setBodySections(getBodySections());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    viewAll,
    subcatsWithEvents,
    marketTypes,
    betslipSelections,
    oddsFormat,
    lazyload,
    maxMarketsToShow,
  ]);

  return (
    <React.Fragment>
      {bodySections}
      {showViewAll && !viewAll ? (
        <ViewMoreBar
          onClick={() => {
            getActiveCategoryData(category.ref);
            setViewAll(true);
            setViewLessButton(true);
          }}
        >
          <ViewMoreLabel>{Translations.get('markets.viewmore')}</ViewMoreLabel>
        </ViewMoreBar>
      ) : (
        viewLessButton && (
          <ViewMoreBar
            onClick={() => {
              setViewAll(false);
              setViewLessButton(false);
            }}
          >
            <ViewMoreLabel>
              {Translations.get('markets.viewless')}
            </ViewMoreLabel>
          </ViewMoreBar>
        )
      )}
    </React.Fragment>
  );
};

EventsTableBody.propTypes = {
  activeTabCategory: PropTypes.object,
  betslipSelections: PropTypes.array,
  category: PropTypes.object,
  marketTypes: PropTypes.array,
  maxMarketsToShow: PropTypes.number,
  subcatsWithEvents: PropTypes.object,
  selectionsToRender: PropTypes.object,
  americanLayout: PropTypes.bool,
  oddsFormat: PropTypes.string,
  displayAmount: PropTypes.any,
  lazyload: PropTypes.any,
  lazyloadlimit: PropTypes.any,
  pageLayoutIsAmerican: PropTypes.bool,
  getActiveCategoryData: PropTypes.func,
  eventsCount: PropTypes.any,
  onVirtuals: PropTypes.bool,
};
