import React from 'react';
import PropTypes from 'prop-types';
import I from 'UI/globals/Icons';
import {
  StyledCategoryTab,
  LabelSpan,
  C,
} from 'UI/navigation/events-category-tab-styles';
import project from '../../../../project';

// eslint-disable-next-line no-unused-vars
const CategoryTab = ({ active, k, label, count, onClick, icon, ...props }) => {
  return (
    <StyledCategoryTab
      title={label}
      active={active}
      key={k}
      onClick={onClick}
      {...props}
    >
      {icon && (
        <I
          marginR={8}
          size={28}
          iconName={icon}
          category={k}
          bespokeSvgSportsIcons={project.bespokeSvgSportsIcons}
          active={active}
          categoryTabs={true}
        />
      )}
      <LabelSpan title={label} fontBold={800}>
        {label}
      </LabelSpan>
      {count > 0 && <C active={active}>{count}</C>}
    </StyledCategoryTab>
  );
};

CategoryTab.propTypes = {
  active: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.string,
  onClick: PropTypes.func,
  k: PropTypes.string || PropTypes.number,
  icon: PropTypes.string,
  count: PropTypes.number,
};

export default CategoryTab;
