import PubSub from 'Services/pubsub/core__pubsub';
import { PubsubEvents } from 'Services/pubsub/core__pubsub.constants';
/**
 * function to emit pubsub to generate betslip
 * @param {String} selectionId
 * @param {object} ordinal // for race events
 */
export const emitSelectionIdForBetSlip = (
  selectionId,
  ordinalData = '',
  eventProviderRef = ''
) => {
  PubSub.emit(PubsubEvents.BetslipUpdate, {
    selectionId,
    ordinalData,
    eventProviderRef,
  });
};
