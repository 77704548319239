import { EventsTable } from 'CORE__UI/apps/EventsApp/core__eventsTable';

import styled from 'styled-components';
import { GREYS } from '../../globals/colours';

const ET_EX = styled(EventsTable)`
  background-color: ${GREYS.silverD};
  box-shadow: none;
`;

export { ET_EX as EventsTable };
