import { TeamName, Team, TeamScore } from 'CORE__UI/apps/EventsApp/core__teams';
import styled, { css } from 'styled-components';
import { GREYS, BRAND } from '../../globals/colours';

const TeamName_GDWN = styled(TeamName)`
  color: ${GREYS.white};
  ${({ isServing }) =>
    isServing &&
    css`
      border-left: 2px solid ${BRAND.secondary};
    `}
`;

const TeamScore_GDWN = styled(TeamScore)`
  font-weight: 700;
  color: ${GREYS.white};
`;

export { TeamName_GDWN as TeamName, Team, TeamScore_GDWN as TeamScore };
